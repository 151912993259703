import React, { useState,useEffect } from 'react';
import { Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from "react-redux";
import { toPng } from 'html-to-image';
import { overallAnalysisApi, categoryAnalysisApi, salesMonitorApi, topSellingProductsApi, salesAnalysisGrowthApi } from "../../features/Dashboard/DashboardSlice";
import { demandAnalysisData, demandAnalysisPlot } from "../../features/DemandAnalysis/DemandAnalysisSlice";
import { fetchChartData } from '../../features/Product/ProductSlice';
import { assortmentTableDataApi, assortmentPlotApi } from "../../features/Assortment/AssortmentSlice";
import ForecastingSetupModal from '../ForecastSetupComponents/ForecastSetupModal/ForecastingSetupModal';
import infoIcon from "../../assets/SVG/icons/Dashboard/info.svg";

const { Option } = Select;

const CardsHeading = ({ heading, headingicon, dataTODownload, showFilter, componentType }) => {
    const currentPath = window.location.pathname;
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const defaultFilterItems = [
        { filterName: 'Yearly', filterValue: 'Yearly' },
        { filterName: 'Monthly', filterValue: 'Monthly' },
        { filterName: 'Weekly', filterValue: 'Weekly' },
        // { filterName: 'Daily', filterValue: 'Daily' }
    ];

    const dashboardFilterItems = [
        { filterName: 'Current Year', filterValue: 'Yearly' },
        { filterName: 'Current Month', filterValue: 'Monthly' },
        { filterName: 'Current Week', filterValue: 'Weekly' },
        { filterName: 'Current Day', filterValue: 'Daily' }
    ];


    const getDefaultFilter = () => {
        if (currentPath === '/demand-analysis' || currentPath === '/products') {
            return 'Monthly';
        }
        return 'Yearly';
    };

    const [filters, setFilter] = useState(getDefaultFilter);
    // Apply default filter value on first render
    useEffect(() => {
        handleFilterChange(filters); // Call with default filter value
    }, [filters]); // Runs only once on mount

    

    const filterItems = currentPath === '/dashboard' ? dashboardFilterItems : defaultFilterItems;
    const filteredItems = componentType === 'salesmonitor' ? filterItems.filter(item => item.filterName !== 'Current Day' && item.filterName !== 'Daily') : filterItems;

    const { levelFilter } = useSelector((state) => state.demandAnalysis || "Set Filter");

    const handleFilterChange = (value) => {
        console.log("filter",value)
        setFilter(value);

        const filter = { filterType: value, payload: value };
        switch (currentPath) {
            case '/demand-analysis':
                dispatch(demandAnalysisData({ filter, filterName: "level" }));
                dispatch(demandAnalysisPlot({ filter, filterName: "level" }));
                break;
            case '/dashboard':
                switch (componentType) {
                    case 'categoryanalysis':
                        dispatch(categoryAnalysisApi(value));
                        break;
                    case 'salesanalysis':
                        dispatch(overallAnalysisApi(value));
                        dispatch(salesAnalysisGrowthApi(value));
                        break;
                    case 'salesmonitor':
                        dispatch(salesMonitorApi(value));
                        break;
                    case 'topSellingProducts':
                        dispatch(topSellingProductsApi(value));
                        break;
                    default:
                        console.log("Component not found");
                        break;
                }
                break;
            case '/products':
                dispatch(fetchChartData({ filter, filterName: "level" }));
                break;
            case '/assortment':
                dispatch(assortmentPlotApi({ filter, filterName: "level" }));
                dispatch(assortmentTableDataApi({ filter, filterName: "level" }));
                break;
            default:
                console.log("No Path");
                break;
        }
    };

    const handleDownload = () => {
        const capitalizeHeader = (header) =>
            header
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
    
        if (dataTODownload.current) {
            const originalBackgroundColor = dataTODownload.current.style.backgroundColor;
            dataTODownload.current.style.backgroundColor = '#ffffff';
    
            toPng(dataTODownload.current)
                .then((dataUrl) => {
                    dataTODownload.current.style.backgroundColor = originalBackgroundColor;
                    const link = document.createElement('a');
                    link.download = 'data_visuals.png';
                    link.href = dataUrl;
                    link.click();
                })
                .catch((error) => {
                    dataTODownload.current.style.backgroundColor = originalBackgroundColor;
                    console.error('Error capturing chart as image:', error);
                });
        } else {
            const headers = Object.keys(dataTODownload[0]).map(capitalizeHeader);
            const dataArray = dataTODownload.map(obj =>
                headers.map(key => obj[key.toLowerCase().replace(/\s+/g, '_')])
            );
            dataArray.unshift(headers);
    
            const worksheet = XLSX.utils.aoa_to_sheet(dataArray);
    
            headers.forEach((_, index) => {
                const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
                if (cell) cell.s = { font: { bold: true } };
            });
    
            const columnWidths = headers.map((_, colIndex) => {
                const maxContentLength = Math.max(
                    ...dataArray.map(row => (row[colIndex] ? String(row[colIndex]).length : 0))
                );
                return { wch: maxContentLength + 2 }; // Adding padding for better spacing
            });
            worksheet['!cols'] = columnWidths;
    
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Forecast Data");
            XLSX.writeFile(workbook, "forecast_data.xlsx");
        }
    };
    

    return (
        <div className='cards_heading'>
            <div className='card_heading_left_elem'>
                {headingicon !== undefined && <img src={headingicon} alt="heading-icon" />}
                <h5>{heading}</h5>
                {heading.toLowerCase() === "category analysis" && (
                    <div 
                        className="info-icon" 
                        onMouseEnter={() => setIsTooltipVisible(true)} 
                        onMouseLeave={() => setIsTooltipVisible(false)} 
                    >
                        <img src={infoIcon} alt="info-icon" />
                        {isTooltipVisible && (
                            <div className="tooltip">
                                <span className="note-label">Note:</span><br />
                                This represents the best sold categories with top brands in those categories.
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className='card_heading_right_elem'>
                {componentType === 'forecastSetupTable' && (
                    <div className='card_heading_download_btn' onClick={() => setIsModalVisible(true)}>
                        <span>Add Forecast Configuration</span>
                    </div>
                )}
                <div className='card_heading_download_btn' onClick={handleDownload}>
                    <span><DownloadOutlined /> Download</span>
                </div>
                {showFilter && (
                    <Select
                        defaultValue={filters}
                        className='cards_heading_select'
                        onChange={handleFilterChange}
                    >
                        {filteredItems.map(option => (
                            <Option key={option.filterValue} value={option.filterValue}>{option.filterName}</Option>
                        ))}
                    </Select>
                )}
            </div>
            <ForecastingSetupModal
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                setIsModalVisible={setIsModalVisible}
            />
        </div>
    );
}

export default CardsHeading;
