import React , {useState, useEffect} from 'react';
import { Card,message, Pagination,Form,Switch } from "antd";
import DeleteConfirmationModal from '../../Modal/DeleteConfirmationModal';

import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css"
import "../../css/Onboarding/OnboardingTable.css"

import OnboardingTable from '../../components/OnBoardingComponents/OnboardingTableComponents/OnboardingTable';

import emptyStateimg from "../../assets/Images/OnboardingEmptyState.png"
import EmptyState from '../../components/CommonComponents/EmptyStateComponent';

import { useDispatch, useSelector } from 'react-redux';
import { createNewEntry , fetchOnboardingData  } from '../../features/Onboarding/OnboardingSlice';
import onboardingBin from "../../assets/Images/OnboardingBin.png";
import { Search } from "@mui/icons-material";
import AddEntryIcon from "../../assets/Images/AddEntryIcon.png";
import { DownOutlined } from "@ant-design/icons";
import { Input, Dropdown, Space, Table, Tag, Button, Menu } from "antd";
import OnboardingEntryModal from "../../components/OnBoardingComponents/OnboardingModal/OnboardingEntryModal";
import { deleteCustomerDetails, resetOnboardingState, fetchCustomerDetails ,updateCustomerDetails, setFilterCustomerDetails} from "../../features/Onboarding/OnboardingSlice";

function OnboardingPage() {
    const dispatch = useDispatch();

    const { customerDetails, Onboardingdata , status , createNewEntryStatus,deleteCustomerDetailsStatus,updateCustomerDetailsStatus,filterCustomerDetails} = useSelector((state) => state.onboarding);
    // let [tableData, setTableData] = useState([]);
    const [istableDataAvailable, setisTableData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);

    const [pagination, setPagination] = useState({ current: 1, pageSize: 4 });



    const start = () => {
        setLoading(true);
        // ajax request after empty completing
        setTimeout(() => {
          setSelectedRowKeys([]);
          setLoading(false);
        }, 1000);
      };
      const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
      };
    
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };



    useEffect(() => {
        // Make API call to get the data
        dispatch(fetchOnboardingData());
    }, []);

     // Effect for create new entry status
  useEffect(() => {
    if (createNewEntryStatus === 'succeeded') {
      message.success('New entry created successfully!',3);
      dispatch(fetchOnboardingData(filterCustomerDetails));
      dispatch(resetOnboardingState());
    }
  }, [createNewEntryStatus, dispatch]);

  // Effect for update customer details status
  useEffect(() => {
    if (updateCustomerDetailsStatus === 'succeeded') {
      message.success('User details updated successfully!',3);
      dispatch(fetchOnboardingData(filterCustomerDetails));
      dispatch(resetOnboardingState());

    }
  }, [updateCustomerDetailsStatus, dispatch]);

  // Effect for delete customer details status
  useEffect(() => {
    if (deleteCustomerDetailsStatus === 'succeeded') {
      message.success('User deleted successfully!',3);
      dispatch(fetchOnboardingData(filterCustomerDetails));
      dispatch(resetOnboardingState());

    }
  }, [deleteCustomerDetailsStatus, dispatch]);

  // Effect for filter customer details
  useEffect(() => {
    if (filterCustomerDetails) {
      dispatch(fetchOnboardingData(filterCustomerDetails));
    }
  }, [filterCustomerDetails, dispatch]);

    useEffect(() => {
        if (filterCustomerDetails && Onboardingdata.length === 0) {
            setisTableData(true);
        } 
        else if(!filterCustomerDetails && Onboardingdata.length === 0){
            setisTableData(false);
            
        } else {
            setisTableData(true);
        }
    }, [Onboardingdata]);




  const [currentSelectedDropdown, setCurrentSelectedDropdown] = useState("");
  const [searchValue, setSearchValue] = useState(''); 
  const [modalVisible, setModalVisible] = useState(false);
  const [mode, setMode] = useState("create");

  const [brandTitle, setBrandTitle] = useState(null);

  const handleSwitchChange = (checked, record) => {
      dispatch(updateCustomerDetails({
          id: record.id,
          is_active: checked ? 'true' : 'false' // Ensure correct value is sent
      }));
  };



  const columns = [
    {
      title: "Select all",
      dataIndex: "Brand_Title",
      className: 'column-padding'
    },
    {
      title: "on boarded",
      dataIndex: "onboarded",
      className: 'column-padding'
    },
    {
      title: "Status",
      dataIndex: "status",
      className: 'column-padding'
    },
    {
      title: "Database",
      dataIndex: "database",
      className: 'column-padding'
    },
    {
      title: "Active / Inactive",
      dataIndex: "user_status",
      className: 'column-padding onboarding_page_is_acive',
      render: (_,record) => (
            <Switch
              checked={record.is_active === 'true'}
              onChange={(checked) => handleSwitchChange(checked, record)}
          />
        
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      className: 'column-padding',
      render: (_, record) => (
        
        <div style={{display:"flex"}}>
        <Button className="add_new_entry_button" onClick={() => handleViewDetails(record)}>
          View Details
        </Button>
        <div>
        <Button className="purple-row" style={{border:"none"}} onClick={() => handleDeleteCustomer(record)} >
          <img src={onboardingBin} alt="Bin Btn" style={{ width: 25, height: 25, marginRight: 8 }} />
          </Button>
      </div>
      </div>
      ),
    },
    
  ];

  const handleViewDetails = (record) => {
    // Handle the view details action here
    setBrandTitle(record.Brand_Title);
    setModalVisible(true);
    dispatch(fetchCustomerDetails(record.id));
    setMode("update");
    
  };

  const handleDeleteCustomer = (record) => {
    setRecordToDelete(record);
    setIsModalVisible(true);
    
  };

  const handleOk = () => {
    if (recordToDelete) {
      dispatch(deleteCustomerDetails({"email":recordToDelete.email}));
    }
    setIsModalVisible(false);
    setRecordToDelete(null);
  };

  const handleDeleteCancel = () => {
    setIsModalVisible(false);
    setRecordToDelete(null);
  };

  const newdata = [];

  for (let i = 0; i < Onboardingdata.length; i++) {
    newdata.push({
      id: `${Onboardingdata[i].id}`,
      Brand_Title: `${Onboardingdata[i].name}`,
      email: `${Onboardingdata[i].email}`,
      onboarded: `${Onboardingdata[i].created_at}`,
      status: `${Onboardingdata[i].onboarding_status}`,
      database: `${Onboardingdata[i].database_name || '-'}` ,
      is_active: `${Onboardingdata[i].is_active || '-'}` ,
      action: "View Details",
    
    });
  }

 
  
  const hasSelected = selectedRowKeys.length > 0;

  const { Search } = Input;
  const dropDownItems = [
    { key: "newest_first", title: "Newest First" },
    { key: "oldest_first", title: "Oldest First" },
    { key: "clear", title: "Clear" },
  ];

  

  const handleItemClick = (item) => {
    setCurrentSelectedDropdown(item.key);
  };
  
  // Only trigger when the dropdown value changes
  useEffect(() => {
    if (currentSelectedDropdown) {
      const value = {
        sort: currentSelectedDropdown,
        keyword: searchValue,
      };
      dispatch(setFilterCustomerDetails([value]));
    }
  }, [currentSelectedDropdown, dispatch]);
  
  const handleSearchAndSort = () => {

    const value = {
      sort: currentSelectedDropdown,
      keyword: searchValue,
    };
    dispatch(setFilterCustomerDetails([value]));  
    // Additional search and sort logic can be added here
  };

  useEffect(() => {
        handleSearchAndSort();
        
    }, [searchValue]);
  
  

  const handleNewEntryClick = () => {
    setMode("create");
    setModalVisible(true);

  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleCreate = (values) => {
    
    // Handle form submission logic here
     dispatch(createNewEntry(values));
     setModalVisible(false);
  };
  const handleUpdate = (values) => {
    
    // Handle form submission logic here
     dispatch(updateCustomerDetails(values));
     setModalVisible(false);
  };
  

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const menu = (
    <Menu>
      {dropDownItems.map((item) => (
        <Menu.Item key={item.key} onClick={() => handleItemClick(item)}>
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

 



    return (
        
        <div className='dashboard_main_container'>
            <Card className=" dashboard_card_containers " loading={loading}>
                <div className="onboarding_conatiner">
                    <div className="onboarding__div1">
                    </div>
                    <div className="onboarding__div2">
                        <h1>Onboarded Brands</h1>
                        <div
                            style={{ marginRight: "12px" }}
                            className="assort_plan_search_sort"
                        >
                            <div className="assort_plan_search_bar">
                                <input
                                    //   style={{background: "white"}}
                                    className="assort_plan_search_input"
                                    type="text"
                                    placeholder="Search here..."
                                    
                                />
                                <Search className="assort_plan_search_icon"
                                    onChange={(e)=>{
                                    setSearchValue(e.target.value);
                                    }} 
                                    onSearch={() => {
                                    handleSearchAndSort();
                                    }}
                                    onPressEnter={handleSearchAndSort}
                                    clearAll
                                />
                            </div>
                        </div>

                        <div className="onboarding_conatiner__dropdown">
                            <Dropdown
                            // menu={{
                            //     items,
                            // }}
                            // trigger={["click"]}
                            overlay={menu}
                            trigger={["click"]}
                            
                            >
                            <Space>
                                {currentSelectedDropdown === "clear"
                                ? "Sort by"
                                : currentSelectedDropdown === "newest_first"
                                ? "Newest First"
                                : "Oldest First"}
                                <DownOutlined style={{ paddingLeft: "28px" }} />
                            </Space>
                            </Dropdown>
                        </div>
                        <Button type="primary" className='add_new_entry_button' onClick={handleNewEntryClick}>
                                        <img src={AddEntryIcon} alt="New entry" style={{ width: 16, height: 16, marginRight: 8 }} />
                                        New entry
                                    </Button>
                        {/* "heyyy" */}
                        <OnboardingEntryModal
                            visible={modalVisible}
                            onCancel={handleCancel}
                            onCreate={handleCreate}
                            onUpdate={handleUpdate}
                            customerDetails={customerDetails}
                            mode={mode}
                            
                        />
                    </div>
                    {/* Onboarding Table here */}
                    <div>
                        <div
                            style={{
                            marginBottom: 16,
                            }}
                        >
                            {/* <Button
                            type="primary"
                            onClick={start}
                            disabled={!hasSelected}
                            loading={loading}
                            >
                            Reload
                            </Button> */}
                            <span
                            style={{
                                marginLeft: 8,
                            }}
                            >
                            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
                            </span>
                        </div>
                        {istableDataAvailable ? (
                            <>
                              <OnboardingTable
                                rowClassName={() => 'purple-row'}
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={pagination}
                                onChange={handleTableChange}
                                dataSource={newdata}
                              />
                              <DeleteConfirmationModal
                                visible={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleDeleteCancel}
                                message="Are you sure you want to delete this record?"
                              />
                            </>
                        ) : (
                            <EmptyState img={emptyStateimg} data="Please create a new entry view the data." page="onboarding" />
                        )}

                    </div>
                </div>
            </Card>
        </div>
        

    )
}

export default OnboardingPage;