import React, { useState, useEffect } from 'react';
import ValianceLogo from '../../assets/SVG/valiancelogo1.svg';
import mainLogo from '../../assets/Images/LoginPageLogo.png';
import { ConfigProvider, Form, Input, Checkbox, Button, message } from 'antd';
import { UserOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import ForecastLogo from "../../assets/Images/ForecastFolioLogo.png";
import { loginUser, profile } from "../../features/Login/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { levelDataApi } from '../../features/Filters/FiltersSlice';


function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [navigationPath, setNavigationPath] = useState('');
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Retrieve user profile from Redux store
  const userProfile = useSelector((state) => state.user.user);

  useEffect(() => {
    if (profileLoaded && userProfile) {
      const userType = userProfile.user_type === 'end-customer' ? 'customer' : userProfile.user_type || '';
      const defaultOption = userType === 'customer' ? 'Dashboard' : 'Profile'; // defaultOption is based on user_type

      const pathToOption = {
        '/dashboard': 'Dashboard',
        '/profile': 'Profile',
      };

      const defaultPath = Object.keys(pathToOption).find(path => pathToOption[path] === defaultOption); // captured path of defaultOption
      localStorage.setItem('userType',userType);
      if (defaultPath) {
        navigate(defaultPath);
      }
    }
  }, [profileLoaded, userProfile, navigate]);

  const handleLogin = async (values) => {
    setLoading(true);
    const userCredentials = values;

    try {
      const loginResult = await dispatch(loginUser(userCredentials));
      if (loginUser.fulfilled.match(loginResult)) {
        message.success("Login successful!");
        
        // Fetch the user profile
        await dispatch(profile());
        dispatch(levelDataApi());
        setProfileLoaded(true); // Indicate that profile fetch is complete
      } else {
        message.error("Login failed");
      }
    } catch (error) {
      message.error("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    handleLogin(values);
  };

  return (
    <main>
      <div className="login">
      <div className='login-content-heading logo-forecast'>
          <img src={ForecastLogo} alt="Forecast-logo" />
          <h1>ForeSight</h1>
        </div>
      <div className="login-logo-row">
        <div className='login-content-main-logo'>
          <img src={mainLogo} alt="logo" />
        </div>
        <div className='login-content-header-logo'>
          <img src={ValianceLogo} alt="Valiance-logo" />
        </div>
      </div>
       

        <div className='login-content'>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: "#172935",
                  defaultBg: "#172935",
                  colorPrimaryHover: "#919191",
                },
              },
            }}
          >
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              layout="vertical"
            >
              <Form.Item
                className='username_box'
                name="email"
                rules={[{ required: true, message: "Please input your e-mail !" }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please input your Password!" }]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  block
                  style={{ color: "white" }}
                  loading={loading} // Show loading state while processing
                >
                  Sign in
                </Button>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Checkbox
                      checked={remember}
                      onChange={(e) => setRemember(e.target.checked)}
                    >
                      Stay signed in
                    </Checkbox>
                    <Link to="/forgot-password">Forgot Password ?</Link>
                  </div>
                </Form.Item>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </div>

        <div className='login-content-footer-centreside'>
          <p>© 2024 Valiance Solutions.</p>
        </div>
        <div className='login-content-footer-rightside'>
          <p>Privacy policy | Cookie policy</p>
        </div>
      </div>
    </main>
  );
}

export default LoginPage;
