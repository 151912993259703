import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import FullscreenModal from "../../CommonComponents/FullscreenModal";

const Graph = ({ data }) => {
    // Prepare series data
    const series = [
        {
            name: 'Forecast',
            data: data.map(item => ({ x: new Date(item.period).getTime(), y: item.forecast }))
        }
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const options = {
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                enabled: true, // Enable zooming
                type: 'x', // Enable only horizontal zooming
                autoScaleYaxis: true // Auto scale Y-axis on zoom
            },
            toolbar: {
                tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: [{
                        icon: '<span>&#x26F6;</span>', // Fullscreen icon
                        index: 4,
                        title: 'Fullscreen',
                        class: 'custom-fullscreen',
                        click: function (chart, options, e) {
                            showModal();
                        }
                    }]
                }
            }
        },
        colors: ['#FFA500'], // Future color (orange)
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 3,
            curve: 'smooth',
            dashArray: [5] // Make the line dotted
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                formatter: function (value) {
                    return new Date(value).toLocaleDateString();
                }
            }
        },
        yaxis: {
            title: {
                text: 'Sales'
            },
            labels: {
                formatter: function (value) {
                    return (value/1000).toFixed(2)+'K';
                }
            }
        }
    };

    return (
        <>
            <Chart options={options} series={series} type="line" height={350} />
            <FullscreenModal visible={isModalVisible} onClose={handleClose} title="Forecasted Graph" options={options} series={series} type="line" height={350} />
        </>
    );
}

export default Graph;
