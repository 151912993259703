import React, { useState } from 'react';
import { Select, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons'; // Import search icon
import refreshIcon from '../../assets/SVG/icons/MyCharts/refresh.svg';
import deleteIcon from '../../assets/SVG/icons/MyCharts/delete.svg';
import AddChartModal from '../../Modal/AddChartModal';
import AddEntryIcon from "../../assets/Images/AddEntryIcon.png";

const { Option } = Select;
const { Search } = Input;

const MyChartsHeading = ({ heading,chartCount, headingicon }) => {
    const [entryModalVisible, setEntryModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleCancel = () => {
        setEntryModalVisible(false);
    };

    const handleCreate = (values) => {
        setEntryModalVisible(false);
    };

    const handleNewEntryClick = () => {
        setEntryModalVisible(true);
    };

    const handleDeleteClick = () => {
        setDeleteModalVisible(true);
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    return (
        <div className='mycharts'>
            <div className='mycharts_top_row'>
                <div className='mycharts_left_elem'>
                    {headingicon !== undefined && <img src={headingicon} alt="heading-icon" />}
                    <div className='heading_container'> {/* Add this container */}
                        <h2>{heading}</h2>
                        <p className='heading_subtext'>{chartCount} charts available</p>
                    </div>
                </div>
                <div className='mycharts_right_elem'>
                    {/* <Button 
                        className='refresh-button' 
                        onClick={() => { 
                            console.log('Refresh clicked');
                        }} 
                    >
                        <img src={refreshIcon} alt="Refresh" />
                    </Button> */}
                    <Button type="primary" className='add_new_entry_button' onClick={handleNewEntryClick}>
                        <img src={AddEntryIcon} alt="Add New" />
                        Add New
                    </Button>
                    {/* <Button type="delete" className='delete_button' onClick={handleDeleteClick}>
                        <img src={deleteIcon} alt="Delete" />
                    </Button> */}
                    <AddChartModal
                        visible={entryModalVisible}
                        onCancel={handleCancel}
                        onCreate={handleCreate}
                    />
                </div>
            </div>
            <div className='mycharts_bottom_row'>
                <div className='mycharts_heading_left_elem'>
                    {/* <Input.Search
                        className='chart_search_input'
                        placeholder="Search chart title"
                        value={searchValue}
                        onChange={handleSearchChange}
                    /> */}
                </div>
                <div className='mycharts_heading_right_elem'>
                    {/* <Select
                        defaultValue="Sort by"
                        className='sort-select'
                    >
                        <Option value="option1">Option 1</Option>
                        <Option value="option2">Option 2</Option>
                        <Option value="option3">Option 3</Option>
                    </Select> */}
                </div>
            </div>
        </div>
    );
}

export default MyChartsHeading;
