import React, { useState, useEffect } from 'react';
import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css";
import ForecastedChart from '../../components/DemandAnalysisComponents/ForecastedGraph/ForecastedChart';
import ForecastedData from '../../components/DemandAnalysisComponents/ForecastedTable/ForecastedData';
import DemandAnalysisHeader from '../../components/DemandAnalysisComponents/DemandAnalysisHeader';
import InSeasonAllocationHeader from '../../components/DemandAnalysisComponents/DemandAnalysisHeader';

import { demandAnalysisData, demandAnalysisPlot } from "../../features/DemandAnalysis/DemandAnalysisSlice";
import { useDispatch } from "react-redux";
import { levelDataApi } from '../../features/Filters/FiltersSlice';
import { useLocation } from 'react-router-dom';

const DemandAnalysisPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [refreshKey, setRefreshKey] = useState(0);
    const [shouldFetchData, setShouldFetchData] = useState(false);

    useEffect(() => {
        if (location.pathname === '/demand-analysis') {
            setShouldFetchData(true);
        } else {
            setShouldFetchData(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (shouldFetchData) {
            dispatch(levelDataApi());
            dispatch(demandAnalysisData({ filter: {filterType:"Yearly"}, filterName: "level" }));
            dispatch(demandAnalysisPlot({ filter: {filterType:"Yearly"}, filterName: "level" }));
        }
    }, [dispatch, shouldFetchData]);

    const handleReset = () => {
        setRefreshKey(prevKey => prevKey + 1); // Increment key to trigger re-render
    };

    return (
        <div className='dashboard_main_container'>
            <InSeasonAllocationHeader key={refreshKey} onReset={handleReset} />
            <ForecastedChart key={refreshKey + 1} />
            <ForecastedData key={refreshKey + 2} />
        </div>
    );
};


export default DemandAnalysisPage;
