import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from "react-redux";
import FullscreenModal from "../../components/CommonComponents/FullscreenModal";

function SalesMonitorGraph({ chartRef }) {
    const { salesMonitorData, salesMOnitorFilter } = useSelector((state) => state.dashboard || {});
    const [xAxisLabel, setXAxisLabel] = useState('weeks'); // Default label for x-axis

    let filteredData = salesMonitorData;
    let keyValuePairs = [];

    if (typeof filteredData === 'object' && !Array.isArray(filteredData)) {
        const keys = Object.keys(filteredData);
        filteredData = Object.values(filteredData);

        keyValuePairs = keys.map((key, index) => ({
            key: key,
            value: filteredData[index]
        }));
    }

    if (salesMOnitorFilter !== "Yearly" && salesMOnitorFilter !== "Weekly" && salesMOnitorFilter !== "Daily") {
        keyValuePairs = keyValuePairs.filter(pair => pair.value !== null);
    }

    const dataPoints = keyValuePairs.map(pair => ({
        x: pair.key,
        y: pair.value ? parseFloat(pair.value.replace('%', '')) : null
    })).filter(point => point.y !== null);

    const series = [{
        name: 'Sales rate',
        data: dataPoints.map(point => point.y)
    }];



    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    // UseEffect to update the x-axis label based on the filter
    useEffect(() => {
        console.log("salesMOnitorFilter",salesMOnitorFilter)
        if (salesMOnitorFilter === "Yearly") {
            setXAxisLabel('Months');
        } else if (salesMOnitorFilter === "Weekly") {
            setXAxisLabel('Days');
        } else if (salesMOnitorFilter === "Daily") {
            setXAxisLabel('Hours');  // Example for Daily
        } else {
            setXAxisLabel('Months');
        }
    }, [salesMOnitorFilter]); // This runs when the filter changes

    const options = {
        chart: {
                type: 'area',
                height: 350,
                zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true
                },
                toolbar: {
                tools: {
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
                customIcons: [{
                    icon: '<span>&#x26F6;</span>',
                    index: 4,
                    title: 'Fullscreen',
                    class: 'custom-fullscreen',
                    click: function () {
                        showModal();
                    }
                }]
                }
            }
        },
        dataLabels: {
                enabled: false
        },
        stroke: {
                curve: 'smooth',
                width: 1.17,
                colors: ['#7B61FF']
        },
        xaxis: {
                categories: dataPoints.map(point => point.x),
                title: {
                text: xAxisLabel, // Dynamically set the x-axis label
                style: {
                color: '#009EFD',
                fontSize: '14px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: 500
                }
                },
                tickAmount: Math.min(dataPoints.length, 10)
        },
        yaxis: {
                title: {
                    text: 'Sales',
                    style: {
                    color: '#009EFD',
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 500
                    }
                },
                labels: {
                    formatter: (val) => `${val}%`
                }
        },
        tooltip: {
            enabled: true,
            theme: 'light',
            y: {
                formatter: (val) => `${val}%`
            },
            marker: {
                show: true
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
                colorStops: [
                {
                    offset: 0,
                    color: "#C8BDFF",
                    opacity: 1
                },
                {
                    offset: 100,
                    color: "rgba(123, 97, 255, 0)",
                    opacity: 1
                }
             ]
        }
        },
        markers: {
        size: 0
        },
        grid: {
        borderColor: '#e7e7e7',
        }
    };

    return (
        <div ref={chartRef} className='sales-monitor-graph'>
        <Chart options={options} series={series} type='area' height={350} />
             <FullscreenModal visible={isModalVisible} onClose={handleClose} title="Forecasted Graph" options={options} series={series} type="line" height={350} />
        </div>
    );
}

export default SalesMonitorGraph;
