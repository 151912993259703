import React, { useState, useEffect } from 'react';
import { Table, Pagination } from 'antd';
import forecastedataIcon from '../../../assets/SVG/icons/DemandAnalysis/forecastedataicon.svg';
import { Card } from 'antd';
import * as XLSX from 'xlsx';
import CardsFilter from '../../CommonComponents/CardsFilterComponent';
import CardsHeading from '../../CommonComponents/CardsHeadingComponent';
import "../../../css/ForecastedData.css";
import { useDispatch, useSelector } from "react-redux";




function ForecastedData() {

    const { filter, forecastDemandAnalysisData } = useSelector((state) => state.demandAnalysis || {});
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);

    }, []);

    const { levelData } = useSelector((state) => state.filter || []);
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    const Lowercase = (str) => str.toLowerCase();

    const columns = [

        ...levelData.map(data => ({
            title: capitalize(data.name), // Ensure you have a method to capitalize strings
            dataIndex: Lowercase(data.name),
            key: Lowercase(data.name),
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
            ),
        })),
        // {
        //     title: 'Brand',
        //     dataIndex: 'brand',
        //     key: 'brand',
        //     onHeaderCell: () => ({
        //         style: { color: '#919191' }
        //     }),
        //     render: (text) => (
        //         <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
        //     ),
        // },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
            ),
        },
        // {
        //     title: 'Gender',
        //     dataIndex: 'gender',
        //     key: 'gender',
        //     onHeaderCell: () => ({
        //         style: { color: '#919191' }
        //     }),
        //     render: (text) => (
        //         <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
        //     ),
        // },
        // {
        //     title: 'Category',
        //     dataIndex: 'category',
        //     key: 'category',
        //     onHeaderCell: () => ({
        //         style: { color: '#919191' }
        //     }),
        //     render: (text) => (
        //         <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
        //     ),
        // },
        {
            title: 'Sales Domain',
            dataIndex: 'sales_domain',
            key: 'sales_domain',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
            ),
        },
        {
            title: 'Time',
            dataIndex: 'aggregation_date',
            key: 'aggregation_date',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
            ),
        },
        {
            title: 'Actual',
            dataIndex: 'sales_amount',
            key: 'sales_amount',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
            ),
        },
        // {
        //     title: 'Bestfit Forecast',
        //     dataIndex: 'forecasted_sales',
        //     key: 'forecasted_sales',
        //     onHeaderCell: () => ({
        //         style: { color: '#919191' }
        //     }),
        //     render: (text) => (
        //         <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
        //     ),
        // },
        {
            title: 'ETS Forecast',
            dataIndex: 'ets',
            key: 'ets',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? parseFloat(text).toFixed(2) : '-'}</div>
            ),
        },
        {
            title: 'Prophet Forecast',
            dataIndex: 'prophet',
            key: 'prophet',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? parseFloat(text).toFixed(2) : '-'}</div>
            ),
        },
        {
            title: 'Bestfit Forecast',
            dataIndex: 'bestfit_forecast',
            key: 'bestfit_forecast',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? parseFloat(text).toFixed(2) : '-'}</div>
            ),
        },
        {
            title: 'Arima',
            dataIndex: 'arima',
            key: 'arima',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? parseFloat(text).toFixed(2) : '-'}</div>
            ),
        },
        {
            title: 'Moving Average',
            dataIndex: 'moving_average',
            key: 'moving_average',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? parseFloat(text).toFixed(2) : '-'}</div>
            ),
        },        
        {
            title: 'Holiday',
            dataIndex: 'holiday_name',
            key: 'holiday_name',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text ? text : '-'}</div>
            ),
        },
    ];


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const currentPageData = forecastDemandAnalysisData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);


    return (
        <Card
            className='dashboard_card_containers '
            loading={loading}
        >
            <div className='forecasted_data_main'>

                <CardsHeading heading="Forecasted Data" headingicon={forecastedataIcon} dataTODownload={forecastDemandAnalysisData} showFilter={false} componentType="forecastedData" />
                <div className='best-selling-brands'>
                    <CardsFilter isAssortment={false} />
                </div>
                <div className='forecasted_data_main'>
                    <Table
                        columns={columns}
                        dataSource={currentPageData}
                        pagination={false}
                        rowKey="key"
                    />
                    <Pagination
                        current={currentPage}
                        total={forecastDemandAnalysisData.length}
                        pageSize={pageSize}
                        onChange={handlePageChange}
                        style={{ marginTop: '16px', textAlign: 'left' }}
                        visible={forecastDemandAnalysisData.length >= 5}
                    />
                </div>
            </div>
        </Card>
    );
}

export default ForecastedData;
