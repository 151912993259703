import React, { useState, useEffect } from 'react';
import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css"
// import Products from '../../components/ProductsPageComponent/Products';
import InSeasonAllocationHeader from '../../components/DemandAnalysisComponents/DemandAnalysisHeader';
// import SimilarItems from '../../components/ProductsPageComponent/ProductsTable/SimilarItems'
import ProductsGraph from '../../components/ProductsPageComponent/ProductsGraph/ProductsPageGraph';
import { drodownDataApi, fetchProductPageFilters } from '../../features/Filters/FiltersSlice';
import { fetchChartData } from '../../features/Product/ProductSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';


function ProductsPage() {
    const [refreshKey, setRefreshKey] = useState(0);
    const dispatch = useDispatch();
    const handleReset = () => {
        setRefreshKey(prevKey => prevKey + 1); // Increment key to trigger re-render
    };

    const location = useLocation();
    const [shouldFetchData, setShouldFetchData] = useState(false);

    useEffect(() => {
        if (location.pathname === '/products') {
            setShouldFetchData(true);
        } else {
            setShouldFetchData(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (shouldFetchData) {
            dispatch(fetchProductPageFilters());
            dispatch(fetchChartData({ filter: {filterType:"Monthly"}, filterName: "level" }));
        }
    }, [dispatch, shouldFetchData]);

    return (
        <div className='dashboard_main_container'>
            <InSeasonAllocationHeader key= {refreshKey} onReset={handleReset} />
            {/* <Products /> */}

            <ProductsGraph key= {refreshKey+1} />
            {/* <SimilarItems /> */}

            {/* <EmptyState img={NodataImg} data="Please apply filters for view the data & charts" page="products" /> */}
        </div>
    )
}

export default ProductsPage;