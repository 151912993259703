import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Spin } from 'antd';
import { useSelector } from "react-redux";
import FullscreenModal from '../../CommonComponents/FullscreenModal';

const Graph = ({ highlightedDay }) => {
    const { status, forecastDemandAnalysisPlot } = useSelector((state) => state.demandAnalysis || {});
    const [historicalData, setHistoricalData] = useState([]);
    const [mlForecasted, setMlForecasted] = useState([]);
    const [isDataReady, setIsDataReady] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const fetchData = async () => {
        if (forecastDemandAnalysisPlot?.actual && forecastDemandAnalysisPlot?.forecast) {
            setHistoricalData(forecastDemandAnalysisPlot.actual);
            setMlForecasted(forecastDemandAnalysisPlot.forecast);
            setIsDataReady(true);
        }
    };

    const getISOWeek = (date) => {
        const target = new Date(date);
        target.setHours(0, 0, 0, 0);
        
        target.setDate(target.getDate() + 4 - (target.getDay() || 7));
        
        const yearStart = new Date(target.getFullYear(), 0, 1);
        
        return Math.ceil((((target - yearStart) / 86400000) + 1) / 7);
    };

    const isWeeklyData = () => {
        const monthDateCounts = {};
        historicalData.forEach(item => {
            const date = new Date(item.aggregation_date);
            if (isNaN(date.getTime())) {
                console.error(`Invalid date: ${item.aggregation_date}`);
                return;
            }
            const monthKey = date.toLocaleString('default', { year: 'numeric', month: 'numeric' });
            if (!monthDateCounts[monthKey]) {
                monthDateCounts[monthKey] = new Set();
            }
            monthDateCounts[monthKey].add(date.getDate());
        });

        return Object.values(monthDateCounts).some(dates => dates.size >= 4 && dates.size <= 5);
    };

    useEffect(() => {
        fetchData();
    }, [forecastDemandAnalysisPlot]);

    console.log("historicalData",historicalData)
    const series = isDataReady ? [
        {
            name: 'Actual',
            data: (() => {
                if (historicalData.length === 1) {
                    const singlePoint = historicalData[0];
                    return [
                        { x: singlePoint.aggregation_date, y: singlePoint.sales_amount }, // original data point
                        { x: singlePoint.aggregation_date, y: 0 } // replicated point with y = 0
                    ];
                } else {
                    return historicalData.map(item => ({
                        x: item.aggregation_date,
                        y: item.sales_amount !== 0 ? item.sales_amount : null
                    }));
                }
            })()
        },
        {
            name: 'Bestfit Forecast',
            data: mlForecasted.map(item => ({
                x: item.aggregation_date,
                y: item.bestfit_forecast !== 0 ? item.bestfit_forecast : null
            }))
        },
        {
            name: 'Prophet',
            data: mlForecasted.map(item => ({
                x: item.aggregation_date,
                y: item.prophet !== 0 ? item.prophet : null
            }))
        },
        {
            name: 'ETS',
            data: mlForecasted.map(item => ({
                x: item.aggregation_date,
                y: item.ets !== 0 ? item.ets : null
            }))
        },
        {
            name: 'Arima', 
            data: mlForecasted.map(item => ({
                x: item.aggregation_date,
                y: item.arima !== 0 ? item.arima : null 
            }))
        }, 
        {
            name: 'Moving Average', 
            data: mlForecasted.map(item => ({
                x: item.aggregation_date,
                y: item.moving_average !== 0 ? item.moving_average : null 
            }))
        }
    ] : [];

    console.log("historicalData",series)


    const options = {
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true
            },
            toolbar: {
                tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: [
                        {
                            icon: '<span>&#x26F6;</span>',
                            index: 4,
                            title: 'Fullscreen',
                            class: 'custom-fullscreen',
                            click: function (chart, options, e) {
                                setIsModalVisible(true);
                            }
                        }
                    ]
                }
            }
        },
        colors: ['#7B61FF', '#FF9F43', '#4BC0C0', '#FF6384', '#36A2EB', '#FFA07A'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [3, 3, 3, 3, 3, 3],
            curve: 'smooth',
            dashArray: [0, 8, 8, 0, 0, 0]
        },
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'MMM yyyy'
            }
        },
        yaxis: {
            title: {
                text: 'Sales'
            },
            labels: {
                formatter: function (value) {
                    return (value / 1000).toFixed(2) + 'K';
                }
            }
        },
        tooltip: {
            x: {
                formatter: function (val) {
                    const date = new Date(val);
                    const weekly = isWeeklyData();
                    
                    if (weekly) {
                        const week = getISOWeek(date);
                        const month = date.toLocaleString('default', { month: 'long' });
                        const year = date.getFullYear();
                        return `Week ${week} of ${year} (${month})`;
                    } else {
                        return date.toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                        });
                    }
                }
            }
        }
    };

    return (
        <div>
            {status === 'loading' || !isDataReady ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <Chart options={options} series={series} type="line" height={350} />
                    <FullscreenModal
                        visible={isModalVisible}
                        onClose={() => setIsModalVisible(false)}
                        title="Forecasted Graph"
                        options={options}
                        series={series}
                        type="line"
                        height={350}
                    />
                </>
            )}
        </div>
    );
};

export default Graph;
