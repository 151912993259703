import React from 'react';
import { Card,Divider } from 'antd';
import MyChartsHeading from './MyChartsHeading';
import MyChartsBody from './MyChartsBody';
import PieChart from './PieChart'; // Ensure PieChart component is imported
import LineChart from './LineChart'; // Ensure LineChart component is imported
import BarChart from './BarChart'; // Ensure BarChart component is imported

const MyChartsComponents = ({ myChartData }) => {
    // if (!selectedChart || !selectedChart["select chart type"]) {
    //     return null; // No chart selected, return nothing
    // }
    const numberOfCharts = myChartData.length;
    return (
        <Card
            className=' dashboard_card_containers'
            // loading={loading}
            // style={{ marginBottom: "20px" }}

        >
            <MyChartsHeading 
                heading="Charts" 
                // headingicon={AssortedIcon} 
                chartCount={numberOfCharts}
            />
            <Divider />
            <MyChartsBody
                myChartData={myChartData}
            />
        </Card>
    );
};

    
export default MyChartsComponents;
