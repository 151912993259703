import React, { useState, useEffect } from 'react';
import { Card, Button } from 'antd';
import "../../css/NoData.css";
import AddEntryIcon from "../../assets/Images/AddEntryIcon.png";
import ChartsEmptyIcon from '../../assets/Images/charts.png';
import OnboardingEntryModal from '../OnBoardingComponents/OnboardingModal/OnboardingEntryModal';
import AddChartModal from '../../Modal/AddChartModal';

import { useDispatch, useSelector } from 'react-redux';
import { createNewEntry } from '../../features/Onboarding/OnboardingSlice';

function EmptyState({ img, data, page, onCreateChart }) {

    const dispatch = useDispatch();
    const { Onboardingdata } = useSelector((state) => state.onboarding || []);

    const [mode, setMode] = useState("create");
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    const handleNewEntryClick = () => {
        setMode("create");
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const handleCreate = (values) => {
        setModalVisible(false);
        onCreateChart(values);
    };

    let Buttondata = '';
    let EmptyStateData = '';
    switch (page) {
        case "onboarding":
            Buttondata = "New entry";
            EmptyStateData = "No Data Available";
            break;
        case "MyChart":
            Buttondata = "Add New";
            EmptyStateData = "Charts Not Found!";
            break;
    }

    return (
        <Card className='dashboard_card_containers' loading={loading}>
            <div className='no_data_subcontainer no_Data_Container'>
                <img src={img} alt="No Data Available" />
                <h3>{EmptyStateData}</h3>
                <p>{data}</p>
                <Button type="primary" className='add_new_entry_button' onClick={handleNewEntryClick}>
                    {
                        page === "MyChart" ? <img src={ChartsEmptyIcon} alt="New entry" /> : <img src={AddEntryIcon} alt="New entry" />
                    }
                    
                    
                    {Buttondata}
                </Button>
            </div>
            {page === "onboarding" && 
                <OnboardingEntryModal
                    visible={modalVisible}
                    onCancel={handleCancel}
                    onCreate={handleCreate}
                    mode={mode}
                />
            }
            {page === "MyChart" &&
                <AddChartModal
                    visible={modalVisible}
                    onCancel={handleCancel}
                    onCreate={handleCreate}
                />
            }
        </Card>
    );
}

export default EmptyState;
