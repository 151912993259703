import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequest } from "../api/api";

// Refactored function to construct full URL
function constructFullUrl(baseEndpoint, params) {
  const queryString = new URLSearchParams(params).toString();
  return queryString ? `${baseEndpoint}?${queryString}` : baseEndpoint;
}

// Async thunk for fetching users
// export const fetchUsers = createAsyncThunk(
//   'userManagement/fetchUsers',
//   async () => {
//     const requestOptions = {
//       method: "GET",
//       redirect: "follow"
//     };

//     const response = await fetch("https://forecast-be-coxlxzb2qa-el.a.run.app/user_profile/fetch-users/?customer_name=Riverbank Chemicals Pte. Ltd", requestOptions);
//     return await response.json();
//   }
// );

export const fetchUsers = createAsyncThunk(
  'userManagement/fetchUsers',
  async (params, thunkAPI) => {
    const userType = localStorage.getItem('userType');
    const baseEndpoint = `${userType}/${userType}_user_list/`;
    const fullUrl = constructFullUrl(baseEndpoint, params);

    try {
      const response = await makeRequest("get", fullUrl);
      console.log("response.data.data",response.data.data)
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk for creating a user
export const createUser = createAsyncThunk(
  'userManagement/createUser',
  async (payload, thunkAPI) => {
    const userType = localStorage.getItem('userType');
    const endpoint = `${userType}/create_user/`;
    const requestBody = payload;
    try {
      const response = await makeRequest("post", endpoint, requestBody);
      return response.data.data;  // Assuming response structure contains the created user in `data`
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'userManagement/deleteUser',
  async(params,thunkAPI) =>{
      const userType = localStorage.getItem('userType')
      console.log("paramas 21",params,userType)
      const request = await makeRequest("delete", `${userType}/usermanagement_delete_user/`,params);
      console.log("request is", request);
      const response = await request.data;
      console.log("inside fetch Customer details", response);
      return response;
  }
)

export const userActiveStatus = createAsyncThunk(
  'userManagement/userActiveStatus',
  async(params,thunkAPI) =>{
      const userType = localStorage.getItem('userType')
      console.log("paramas 21",params,userType,`auth/user_active_status/${params}/`)
      const request = await makeRequest("patch", `auth/user_active_status/${params}/`);
      console.log("request is", request);
      const response = await request.data;
      console.log("inside fetch Customer details", response);
      return response;
  }
)


// Initial state for user management slice
const initialState = {
  users: [],
  isActiveStatus: null,
  status: 'idle',
  error: null
};

// Create user management slice
const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(userActiveStatus.pending, (state) => {
        state.status = 'loading';
        state.isActiveStatus = 'loading';
      })
      .addCase(userActiveStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isActiveStatus = 'succeeded';
        state.users = action.payload;
      })
      .addCase(userActiveStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.isActiveStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// Export the reducer
export default userManagementSlice.reducer;
