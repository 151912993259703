import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown, Menu, Avatar } from 'antd';
import walleticon from '../assets/SVG/Navbar/solar_wallet-bold.svg';
import Folioicon from '../assets/SVG/Navbar/ForecastFolioLogo3.svg';
import userIcon from '../assets/SVG/Navbar/Usericon.svg';
// import CustomerIcon from '../assets/Images/NavBar/riverbank_chemicals_group_logo.jpeg';
import lightmodeIcon from '../assets/Images/NavBar/lightmode.png';
import darkmodeIcon from '../assets/Images/NavBar/darkmode.png';
import { Search } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const { SubMenu } = Menu;

const Navbar = () => {
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isLightMode, setIsLightMode] = useState(true);
  const location = useLocation();

  const userProfile = useSelector((state) => state.user.user);

  const firstName = userProfile?.first_name || 'User';
  const lastName = userProfile?.last_name || '';
  const designation = userProfile?.designation || 'Administrator';
  const profilePicture = userProfile?.profile_img || userIcon;
  const customerNames = userProfile?.customer_names || []; 

  useEffect(() => {
    const updateCurrentTime = () => {
      setCurrentTime(new Date());
    };
    // Set up an interval to update the time every second
    const intervalId = setInterval(updateCurrentTime, 1000);
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Function to get the current date and format it as "DD Mon YYYY"
    const getCurrentDate = () => {
      const dateObj = new Date();
      const options = { year: 'numeric', month: 'short', day: '2-digit' };
      return dateObj.toLocaleDateString('en-US', options);
    };
    // Set the current date when the component mounts
    setCurrentDate(getCurrentDate());
  }, []);

  const toggleTheme = () => {
    setIsLightMode(prevMode => !prevMode);
  };

  const getCurrentSection = () => {
    switch (location.pathname) {
      case '/dashboard':
        return 'Dashboard';
      case '/demand-analysis':
        return 'Demand Analysis';
      case '/assortment':
        return 'Assortment';
      case '/products':
        return 'Products';
      case '/inventory-insights':
        return 'Inventory Insights';
      case '/mycharts':
        return 'My Charts';
      case '/profile':
        return 'Profile';
      case '/onboarding':
        return 'Onboarding';
      case '/usermanagement':
        return 'User management';
      case '/allocationplanning':
        return 'Data & Files';
      case '/forecast-setup':
        return 'Forecast Setup';
      default:
        return 'Dashboard'; // Default section if route doesn't match
    }
  };

  const currentSection = getCurrentSection();

  const menu = (
    <Menu mode="vertical" subMenuOpenDelay={0.1}>
      <Menu.Item key="0">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
          <div>
            <Avatar style={{ width: '40px', height: '40px', borderRadius: '50%' }} src={profilePicture} />
          </div>
          <div>
            <strong>{firstName} {lastName}</strong><br />
            <span>{userProfile?.email || 'email@example.com'}</span>
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <a href="/profile">Profile</a>
      </Menu.Item>
      <SubMenu key="sub1" title="Customer Details" popupClassName="custom-submenu">
        {customerNames.map((customer, index) => (
          <Menu.Item key={`customer-${index}`}>{customer}</Menu.Item>
        ))}
      </SubMenu>
      <Menu.Item key="2">
        <a>Settings</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='navbar_main'>
      <div className='navbar_left_elem'>
        <img src={Folioicon} style={{ width: '50px', height: '50px' }} alt="wallet-icon" />
        <span style={{ color: '#7B61FF' }}>ForeSight</span>
        <div className='navbar_vertical_line'></div>
        <span>{currentSection}</span>
      </div>

      <div className='navbar_right_elem'>
        {/* <div className='navbar_search_box'>
          <div className="search-bar">
            <input className="search-input" type="text" placeholder="Search here..." />
            <Search className="search-icon" />
          </div>
        </div> */}
        <div className="navbar_date">
          <span style={{ color: '#767676' }}>{currentDate}</span>
          {/* <span>{currentTime.toLocaleTimeString()}</span> */}
        </div>
        <div className='navbar_dark_mode'>
          {/* <img
            src={isLightMode ? lightmodeIcon : darkmodeIcon}
            alt={isLightMode ? "Light-mode" : "Dark-mode"}
            onClick={toggleTheme}
            style={{ width: '40px', height: '40px', cursor: 'pointer' }}
          /> */}
          {/* <img src={CustomerIcon} style={{ width: '50px', height: '50px', borderRadius: '50%' }} alt="Customer-icon" /> */}
        </div>
        <div className='navbar_vertical_line'></div>
        
          <div className='navbar_user_detail'>
            <p>{firstName} {lastName}</p>
            <p>{designation}</p>
          </div>
          <div className='navbar_user_icon'>
            <Dropdown overlay={menu} trigger={['click']}>
              <Avatar className='navbar_usericon_profilepic' style={{ cursor: 'pointer', width: '50px', height: '50px', borderRadius: '50%' }} src={profilePicture} alt="User-Icon" />
            </Dropdown>
          </div>
      </div>
    </div>
  );
}

export default Navbar;
