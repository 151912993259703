import React, {useState,useEffect} from 'react'; 
import { Modal, Button, Select,notification } from 'antd';
import { ReactComponent as GraphIcon } from '../assets/SVG/icons/MyCharts/bar.svg';
import { ReactComponent as LineChartIcon } from '../assets/SVG/icons/MyCharts/line.svg'; 
import { ReactComponent as PieChartIcon } from '../assets/SVG/icons/MyCharts/pie.svg';
import Group66 from '../assets/Images/Group66.png'; 
import { useDispatch, useSelector } from 'react-redux';
import '../css/MyCharts/chartSettingsModal.css'; 
import { createChart } from "../features/MyCharts/MyChartsSlice";
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

function ChartSettingsModal({ visible, onCancel,onBack, chartType, chartName, dataSource }) {

    const { myChartDropdownValues, chartCreationStatus, chartCreationError } = useSelector((state) => state.myCharts);
    const { parameter_1, parameter_2, duration } = myChartDropdownValues || {};
    const parameter_1_values = myChartDropdownValues?.parameter_1_values || [];
    const parameter_2_values = myChartDropdownValues?.parameter_2_values || [];
    const [selectedParameter1, setSelectedParameter1] = useState(parameter_1_values?.[0]);
    const [selectedParameter2, setSelectedParameter2] = useState(parameter_2_values?.[0]);
    const [selectedDuration, setSelectedDuration] = useState(duration?.[0]);
    const dispatch = useDispatch();

    const resetSelections = () => {
        setSelectedParameter1(parameter_1_values?.[0]);
        setSelectedParameter2(parameter_2_values?.[0]);
        setSelectedDuration(duration?.[0]);
    };


    const handleBack = () => {
        resetSelections();
        onBack(); // Example action
    };

    const handleCancel = () => {
        resetSelections();
        onCancel();
    };

    useEffect(() => {
        if (chartCreationStatus === 'succeeded') {
            notification.success({
                message: 'Chart Created',
                description: 'Your chart has been created successfully!',
            });
            window.location.reload(); // Reload the page to show the new data
        }
        if(chartCreationStatus === 'failed'){
            notification.error({
                message: 'Chart Creation Failed',
                description: chartCreationError,
            })
        }
    }, [chartCreationStatus]);

    const handleCreate = () => {
        dispatch(createChart({
            parameter_1: selectedParameter1,
            parameter_2: selectedParameter2,
            duration: selectedDuration,
            chart_type: chartType,
            chart_name: chartName,
            data_source: dataSource,
        }));

    };

    const renderContent = () => {
        

        switch (chartType) {
            case 'bar_chart':
                return (
                    <>
                        <div className="chart-header">
                            <GraphIcon className="icon" />
                            <p className="graph-chart-title">
                                Set Parameters for <b>Bar Chart</b>
                            </p>
                        </div>
                        {renderAxisSelection(parameter_1, 'Select a parameter to display on the y-axis of the Bar chart.',parameter_1_values, setSelectedParameter1)}
                        {renderAxisSelection(parameter_2, 'Select a parameter to display on the x-axis of the Bar chart.',parameter_2_values, setSelectedParameter2)}
                        {renderDurationSelection('Select Duration', duration, setSelectedDuration)}
                    </>
                );
            case 'line_chart':
                return (
                    <>
                        <div className="chart-header">
                            <LineChartIcon className="icon" />
                            <p className="line-chart-title">
                                Set Parameters for <b>Line Chart</b>
                            </p>
                        </div>
                        {renderAxisSelection(parameter_1, 'Select a parameter to display on the y-axis of the Line chart.',parameter_1_values, setSelectedParameter1)}
                        {renderAxisSelection(parameter_2, 'Select a parameter to display on the x-axis of the Line chart.',parameter_2_values, setSelectedParameter2)}
                        {renderDurationSelection('Select Duration', duration, setSelectedDuration)}
                    </>
                );
            case 'pie_chart':
                return (
                    <>
                        <div className="chart-header">
                            <PieChartIcon className="icon" />
                            <p className="graph-chart-title">
                                Set Parameters for <b>Pie Chart</b>
                            </p>
                        </div>
                        {renderAxisSelection(parameter_1, 'Classify data by selecting category.',parameter_1_values, setSelectedParameter1)}
                        {renderAxisSelection(parameter_2, 'Select the values you want to display.',parameter_2_values, setSelectedParameter2)}
                        {renderDurationSelection('Select Duration', duration, setSelectedDuration)}
                    </>
                );
            default:
                return null;
        }
    };

    const renderAxisSelection = (label, description, options, setValue) => (
        <div className="axis-selection">
            {console.log("options",options)}
            <p className="axis-label">{label}</p>
            <p className="axis-description">{description}</p>
            <Select className="axis-select" onChange={setValue} defaultValue={options?.[0]}>
                {options.map((opt) => (
                    <Option key={opt} value={opt}>{opt.replace(/_/g, ' ')}</Option>
                ))}
            </Select>
        </div>
    );

    const renderDurationSelection = (label, options, setValue) => (
        <div className="duration-selection">
            <p className="duration-label">{label}</p>
            <Select className="duration-select" onChange={setValue} defaultValue={options?.[0]}>
                {(options || []).map((opt) => (
                    <Option key={opt} value={opt}>{opt.replace(/_/g, ' ')}</Option>
                ))}
            </Select>
            <p className="duration-note">
                *The displayed durations are based on the available data in the dataset. Please update the data if you need additional variations.
            </p>
        </div>
    );

    return (
        <Modal
            className="chart-modal-container"
            visible={visible}
            title={(
                <span className="modal-title">
                    <img className="modal-icon" src={Group66} alt="Chart Icon" />
                    <div className="modal-header">
                        <div className="modal-header-title">Creating Chart - Final Step</div>
                        <p className="modal-header-description">
                            Design a chart tailored to your needs to monitor the specific data you want to track.
                        </p>
                    </div>
                </span>
            )}
            onCancel={handleCancel}
            footer={
                <>
                    <Button onClick={handleBack} className="back-button">Back</Button>
                    <Button onClick={handleCreate} type="primary" className="create-button">Create</Button>
                </>
            }
            width={700}
        >
            {renderContent()}
        </Modal>
    );
}

export default ChartSettingsModal;
