import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css";
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import InsightStocks from "../../components/InventoryInsightComponents/InsightStocks";
import ExpectedStockoutTable from '../../components/InventoryInsightComponents/ExpectedStockoutTable';
import OpportunityLostGraph from '../../components/InventoryInsightComponents/OpportunityLostGraph';
import { levelDataApi } from '../../features/Filters/FiltersSlice';
import { fetchInventoryInsights, fetchAllItems, fetchAllLocations } from '../../features/InventoryInsights/InventoryInsightsSlice';



function InventoryInsightsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(levelDataApi());
    // dispatch(fetchAllItems());
    // dispatch(fetchAllLocations());

  }, [dispatch]);
  return (
    <div className='dashboard_main_container'>

            <InsightStocks />
            <OpportunityLostGraph />
            <ExpectedStockoutTable />

            {/* <EmptyState img={NodataImg} data="Please apply filters for view the data & charts" page="products" /> */}
        </div>
  )
}

export default InventoryInsightsPage