import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button, Checkbox, Input, Badge } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { FixedSizeList as List } from 'react-window';
import { fetchBrands, fetchCountries, fetchGender, fetchCategory, setSelectedBrand, setSelectedCountry, setSelectedCategory, setSelectedGender, clearSelections, fetchItems, fetchLocations } from '../features/Login/Modal/modalSlice';
import { demandAnalysisData, demandAnalysisPlot } from "../features/DemandAnalysis/DemandAnalysisSlice";
import { fetchSimilarItems, fetchChartData } from '../features/Product/ProductSlice';
import { setSelectedItem, setSelectedLocation } from '../features/Product/ProductSlice';
import { assortmentTableDataApi, assortmentPlotApi } from '../features/Assortment/AssortmentSlice';
import { drodownDataApi, fetchProductPageFilters } from '../features/Filters/FiltersSlice';
import "../css/BrandModal.css";
import { useLocation } from 'react-router-dom';

const BrandModal = ({ visible, closeModal, buttonType }) => {
  const dispatch = useDispatch();
  const { brands, countries, genders, categories, loading, selectedBrand, selectedCountry, selectedCategory, selectedGender, items, locations } = useSelector((state) => state.modal);
  const { dropdownData, globalFilters, countriesData, seasonData,productPageData } = useSelector((state) => state.filter);
  const [selectedItems, setSelectedItems] = useState([]);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);


  const currentPath = window.location.pathname;

  useEffect(() => {
    if (visible) {
      const savedInputFields = localStorage.getItem("inputFields");
      if (savedInputFields) {
        const inputFields = JSON.parse(savedInputFields);
        console.log("savedInputFields",inputFields)
        const field = inputFields.find((field) => field.value === buttonType);
        if (field && field.selectAs) {
          setSelectedItems(field.selectAs.split(', '));
        }
      }
    }
  }, [visible, buttonType]);

  useEffect(() => {
    if (buttonType === 'Items') {
      dispatch(fetchItems());
    } else if (buttonType === 'Location') {
      dispatch(fetchLocations());
    }
  }, [buttonType, dispatch]);

  const itemData = buttonType === "Location" ? locations :buttonType === "Items" ? items : buttonType === "country" ? countriesData : dropdownData[buttonType.toLowerCase()];

  const itemDataProductPage = buttonType.toLowerCase() === "product".toLowerCase() ? productPageData["sku"] : productPageData[buttonType.toLowerCase()];

  
  
  const filteredItems = useMemo(() => {

    if (location.pathname=="/products" && itemDataProductPage?.length>0){
      return itemDataProductPage?.filter(item => typeof item === 'string' && item.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    else{
        return itemData?.filter(item => typeof item === 'string' && item.toLowerCase().includes(searchTerm.toLowerCase()));

    }
  }, [searchTerm, itemData]);

  

  const handleApply = async () => {

    


    const payload = {
        ...globalFilters,
        [buttonType.toLowerCase()]: selectAll ? [] : (selectedItems?.length > 0 ? selectedItems : []),
    };
    
    dispatch(fetchProductPageFilters());
    dispatch(drodownDataApi(payload)).then(() => {
      console.log("selectedItems",selectedItems)
      switch (currentPath) {
        case '/demand-analysis':
          dispatch(demandAnalysisData({ selectedItems, filterName: buttonType.toLowerCase() }));
          dispatch(demandAnalysisPlot({ selectedItems, filterName: buttonType.toLowerCase() }));
          break;
        case '/assortment':
          dispatch(assortmentTableDataApi({ selectedItems, filterName: buttonType.toLowerCase() }));
          dispatch(assortmentPlotApi({ selectedItems, filterName: buttonType.toLowerCase() }));
          break;
        case '/products':
          if (payload.brand && payload.brand.length > 0) {
            dispatch(fetchProductPageFilters({keyword:payload.brand}));

          }
          dispatch(setSelectedItem(selectedItems));
          // dispatch(fetchSimilarItems({ selectedItems, filterName: buttonType.toLowerCase() }));
          dispatch(fetchChartData({ selectedItems, filterName: buttonType.toLowerCase() }));
          
          break;
        default:
          console.log("No Path");
          break;
      }
      setSelectedItems([]);
      closeModal();
    });
  };

  const handleClose = () => {
    setSelectedItems([]);
    closeModal();
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectedItems(checked ? filteredItems.map((item) => item) : []);
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems((prev) => {
      if (prev.includes(item)) {
        return prev.filter((i) => i !== item); // Remove if already selected
      } else {
        return [...prev, item]; // Add if not selected
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSelection = () => {
    setSelectedItems([]);
    setSelectAll(false);
    dispatch(clearSelections());
    dispatch(fetchBrands());
    dispatch(fetchCountries());
  };

  const isChecked = (item) => selectedItems.includes(item);
  

  const Row = ({ index, style }) => {
    const item = filteredItems[index];
    
    return (
      <div
        style={style} // Apply the style passed by react-window
        onClick={() => handleCheckboxChange(item)}
        className="brand-row"
      >
        <Checkbox
          checked={isChecked(item)}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.stopPropagation();
            handleCheckboxChange(item);
          }}
          className="custom-checkbox"
        />
        <span className="brand-row-text">{item}</span>
      </div>
    );
  };

  return (
    <Modal
      title={`Select ${_.capitalize(buttonType.toLowerCase())}`}
      visible={visible}
      onCancel={handleClose}
      className="custom-modal"
      footer={[
        <div key="footer" className='brand-modal-footer'>
          <div className='brand-modal-footer-div'>
            <Button
              shape="circle"
              icon={<CloseOutlined />}
              onClick={handleClearSelection}
              className='brand-modal-footer-button'
            />
            <Badge
              count={
                <span>
                  <span className='brand-modal-footer-badge1'>{selectedItems?.length}</span>
                  <span className='brand-modal-footer-badge2'> selected</span>
                </span>
              }
              className='brand-modal-footer-badge'
            />
          </div>
          <Button key="apply" type="primary" onClick={handleApply} className='brand-modal-footer-apply-button'>
            Apply
          </Button>
        </div>
      ]}
    >
      
      <div className='brand-modal-sub-header'>
        <Checkbox
          onChange={handleSelectAll}
          checked={selectAll}
          className="custom-checkbox"
        >
          Select All
        </Checkbox>
        <Input
          placeholder="Search here.."
          value={searchTerm}
          onChange={handleSearchChange}
          className='brand-modal-search-bar'
          prefix={<SearchOutlined className='brand-modal-search-prefix' />}
        />
      </div>
      <hr className="brand-modal-hr" />
      <div className="brand-list">
        <List
          className='filter-list'
          height={300} // Adjust height to fit your modal
          itemCount={filteredItems?.length}
          itemSize={50} // Adjust item size to fit your design
          width="100%" // Adjust width to fit your modal
        >
          {Row}
        </List>
      </div>
    </Modal>
  );
};

export default BrandModal;
