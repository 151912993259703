import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

const buildQueryParams = (state, globalFilters) => {
    let queryParams = [];
    console.log("state",state)
    // Loop through globalFilters dynamically
    for (const [key, value] of Object.entries(globalFilters)) {
        if (Array.isArray(value)) {
            // Handle array values by appending each item with the corresponding key
            queryParams.push(...value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item.toLowerCase())}`));
        } else {
            // If it's not an array, just append the key and value pair
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toLowerCase())}`);
        }
    }

    // Existing state filters
    if (state.genderFilter && state.genderFilter.length > 0 && state.genderFilter[0] !== "All") {
        queryParams.push(...state.genderFilter.map(item => `gender=${encodeURIComponent(item.toLowerCase())}`));
    }

    if (state.levelFilter) {
        queryParams.push(`level=${encodeURIComponent(state.levelFilter.toLowerCase())}`);
    }

    return queryParams.join('&');
};

export const assortmentTableDataApi = createAsyncThunk(
    'assortment/getTableData',
    async (params, thunkAPI) => {
        let endpoint = "";
        let joint = "";

        switch (params.filterName) {
            case "duration":
                thunkAPI.dispatch(setFilter(params.filter.payload));
                break;
            case "season":
                thunkAPI.dispatch(setSeasonFilter(params.selectedItems));
                break;
            default:
                break;
        }

        const state = thunkAPI.getState();
        const globalFilter = state.filter.globalFilters
        // joint = buildQueryParams(state.assortment, ['levelFilter']);
        joint = buildQueryParams(state.assortment, globalFilter);

        endpoint = `/customer/assortment_planning_table/?${joint}`;
        const request = await makeRequest("get", endpoint);
        const response = await request.data;
        return response;
    }
);

export const assortmentPlotApi = createAsyncThunk(
    'assortment/assortmentPlotApi',
    async (params, thunkAPI) => {

        let endpoint = "";
        let joint = "";
        // console.log("params",params)
        switch (params.filterName) {
            case "duration":
                thunkAPI.dispatch(setFilter(params.filter.payload));
                break;
            case "season":
                thunkAPI.dispatch(setSeasonFilter(params.selectedItems));
                break;
            case "level":
                thunkAPI.dispatch(setLevelFilter(params.filter.filterType));
                break;
            default:
                break;
        }

        const state = thunkAPI.getState();
        console.log("statestate",state)
        const globalFilter = state.filter.globalFilters
        // joint = buildQueryParams(state.assortment, ['levelFilter']);
        console.log("statestate",state)
        joint = buildQueryParams(state.assortment, globalFilter);

        endpoint = `customer/assortment_planning_chart/?${joint}`;
        const request = await makeRequest("get", endpoint);
        const response = await request.data;
        return response;
    }
);

const assortmentSlice = createSlice({
    name: "assortment",
    initialState: {
        status: 'idle',
        filter: 'Set Filter',
        levelFilter: "Yearly",
        season: ["All"],
        assortmentTableData: [],
        assortmentPlot: [],
        assortmentPlotMainData: [],
    },
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setLevelFilter: (state, action) => {
            state.levelFilter = action.payload;
        },
        setSeasonFilter: (state, action) => {
            state.season = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(assortmentTableDataApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(assortmentTableDataApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.assortmentTableData = action.payload;
            })
            .addCase(assortmentTableDataApi.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(assortmentPlotApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(assortmentPlotApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.assortmentPlot = action.payload;
                

                const groupedData = action.payload.reduce((acc, curr) => {
                    if (!acc[curr.location]) {
                        acc[curr.location] = [];
                    }
                    acc[curr.location].push(curr);
                    return acc;
                }, {});

                const mainData = Object.keys(groupedData).map(location => ({
                    name: location,
                    data: groupedData[location].map(item => ({
                        x: item.total_rate_of_sale,
                        y: item.location
                    })),
                    color: `#${Math.floor(Math.random() * 16777215).toString(16)}`
                }));
                state.assortmentPlotMainData = mainData;
                console.log("action.payload",action.payload,mainData)
            })
            .addCase(assortmentPlotApi.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setFilter, setLevelFilter, setSeasonFilter } = assortmentSlice.actions;
export default assortmentSlice.reducer;
