import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const overallAnalysisApi = createAsyncThunk(
    'dashboard/getOverallAnalysisData',
    async (params, thunkAPI) => {
        // console.log(params)

        let endpoint = "";
        if (params !== '') {
            endpoint = "level=" + params.toLowerCase();
        } else {
            endpoint = "level=yearly";
        }
        const request = await makeRequest("get", `customer/brand-analysis/?${endpoint}`);
        const response = await request.data;
        return response;
    }
);
export const salesAnalysisGrowthApi = createAsyncThunk(
    'dashboard/getSalesAnalysisGrowthData',
    async (params, thunkAPI) => {
        // console.log(params)

        let endpoint = "";
        if (params !== '') {
            endpoint = "level=" + params.toLowerCase();
        } else {
            endpoint = "level=yearly";
        }
        const request = await makeRequest("get", `customer/sales-analysis/?${endpoint}`);
        const response = await request.data;

        return response;
    }
);
export const categoryAnalysisApi = createAsyncThunk(
    'dashboard/getCategoryAnalysisData',
    async (params, thunkAPI) => {
        // console.log(params)
        let endpoint = "";
        if (params !== '') {
            endpoint = "level=" + params.toLowerCase();
        } else {
            endpoint = "level=yearly";
        }
        const request = await makeRequest("get", `customer/category_analysis/?${endpoint}`);
        const response = await request.data;
        return response;
    }
);
export const salesMonitorApi = createAsyncThunk(
    'dashboard/getSalesMonitorData',
    async (params, thunkAPI) => {
        // console.log(params)
        thunkAPI.dispatch(setSalesMonitorFilter(params));
        let endpoint = "";
        if (params !== '') {
            endpoint = "level=" + params.toLowerCase();
        } else {
            endpoint = "level=yearly";
        }
        const request = await makeRequest("get", `customer/sales-monitor/?${endpoint}`);
        const response = await request.data;
        console.log("Sales monitor: ", response);

        return response;
    }
);
export const topSellingProductsApi = createAsyncThunk(
    'dashboard/getTopSellingProductsData',
    async (params, thunkAPI) => {
        // console.log(params)
        let endpoint = "";
        if (params !== '') {
            endpoint = "level=" + params.toLowerCase();
        } else {
            endpoint = "level=yearly";
        }
        const request = await makeRequest("get", `customer/country_analysis/?${endpoint}`);
        const response = await request.data;
        return response;
    }
);

const DashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        status: 'idle',
        overallAnalysisFilter: "Yearly",
        categoryAnalysisFilter: "Yearly",
        salesMOnitorFilter: "Yearly",
        topSellingProductsFilter: "Yearly",
        overallAnalysisData: [],
        salesAnalysisGrowthData: {},
        categoryAnalysisData: [],
        salesMonitorData: {},
        topSellingProductsData: [],
    },
    reducers: {
        setOverallAnalysisFilter: (state, action) => {
            state.filter = action.payload;
        },
        setCategoryAnalysisFilter: (state, action) => {
            state.brandFilter = action.payload;
        },
        setSalesMonitorFilter: (state, action) => {
            state.salesMOnitorFilter = action.payload;
        },

        setTopSellingProductsFilter: (state, action) => {
            state.countryFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(overallAnalysisApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(overallAnalysisApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.overallAnalysisData = action.payload;
            })
            .addCase(overallAnalysisApi.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(categoryAnalysisApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(categoryAnalysisApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categoryAnalysisData = action.payload;
            })
            .addCase(categoryAnalysisApi.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(salesMonitorApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(salesMonitorApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.salesMonitorData = action.payload;
            })
            .addCase(salesMonitorApi.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(topSellingProductsApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(topSellingProductsApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.topSellingProductsData = action.payload;
            })
            .addCase(topSellingProductsApi.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(salesAnalysisGrowthApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(salesAnalysisGrowthApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.salesAnalysisGrowthData = action.payload;
            })
            .addCase(salesAnalysisGrowthApi.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setOverallAnalysisFilter, setCategoryAnalysisFilter, setSalesAnalysisGrowthFilter, setSalesMonitorFilter, setTopSellingProductsFilter } = DashboardSlice.actions;

export default DashboardSlice.reducer;
