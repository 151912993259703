import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";


const buildQueryParams = (state, globalFilters) => {
    console.log(globalFilters)
    console.log(state)
    let queryParams = [];

    // Loop through globalFilters dynamically
    for (const [key, value] of Object.entries(globalFilters)) {
        if (Array.isArray(value)) {
            // Handle array values by appending each item with the corresponding key
            queryParams.push(...value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item.toLowerCase())}`));
        } else {
            // If it's not an array, just append the key and value pair
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toLowerCase())}`);
        }
    }

    if (state.levelFilter) {
        queryParams.push(`level=${encodeURIComponent(state.levelFilter.toLowerCase())}`);
    }

    if (state.filter) {
        queryParams.push(`filter_type=${state.filter}`);
    }

    return queryParams.join('&');
};


export const demandAnalysisData = createAsyncThunk(
    'demandAnalysis/getGraphData',
    async (params, thunkAPI) => {
        console.log(params)

        let endpoint = "";
        let joint = "";

        switch (params.filterName) {

            case "duration":
                thunkAPI.dispatch(setFilter(params.filter.payload));
                break;
            case "level":
                thunkAPI.dispatch(setLevelFilter(params.filter.filterType));
                break;
            default:
                break;
        }

        const state = thunkAPI.getState();
        // console.log(state)
        const globalFilter = state.filter.globalFilters
        joint = buildQueryParams(state.demandAnalysis, globalFilter);

        endpoint = `customer/sales_forecast_report_view/?${joint}`
        const request = await makeRequest("get", endpoint);

        // console.log(request)
        const response = await request.data;
        return response;
    }
);
export const demandAnalysisPlot = createAsyncThunk(
    'demandAnalysis/getPlotData',
    async (params, thunkAPI) => {
        let endpoint = "";
        let joint = "";
        switch (params.filterName) {
            case "duration":
                thunkAPI.dispatch(setFilter(params.filter.payload));
                break;
            case "level":
                thunkAPI.dispatch(setLevelFilter(params.filter.filterType));
                break;
            default:
                break;
        }

        const state = thunkAPI.getState();
        const globalFilter = state.filter.globalFilters
        joint = buildQueryParams(state.demandAnalysis, globalFilter);
        endpoint = `customer/sales_forecast_trend_view/?${joint}`
        // console.log("endpoint",endpoint)
        const request = await makeRequest("get", endpoint);
        const response = await request.data;
        // console.log('API response:', response); // Log the API response
        return response;
    }
);

const demandAnalysisSlice = createSlice({
    name: "demand_analysis",
    initialState: {
        status: 'idle',
        filter: 'past_m_future_n_years&m=1&n=1',
        levelFilter: "Monthly",
        forecastDemandAnalysisData: [],
        forecastDemandAnalysisPlot: [],
    },
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setLevelFilter: (state, action) => {
            state.levelFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(demandAnalysisData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(demandAnalysisData.fulfilled, (state, action) => {
                // console.log('Thunk fulfilled action:', action); // Log the action
                state.status = 'succeeded';
                state.forecastDemandAnalysisData = action.payload;
            })
            .addCase(demandAnalysisData.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(demandAnalysisPlot.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(demandAnalysisPlot.fulfilled, (state, action) => {
                // console.log('Thunk fulfilled action:', action); // Log the action
                state.status = 'succeeded';
                state.forecastDemandAnalysisPlot = action.payload;
            })
            .addCase(demandAnalysisPlot.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setFilter, setLevelFilter} = demandAnalysisSlice.actions;

export default demandAnalysisSlice.reducer;
