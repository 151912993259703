import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import durationIcon from "../../assets/SVG/Demand_Analysis/duration.svg";
import { demandAnalysisData, demandAnalysisPlot } from "../../features/DemandAnalysis/DemandAnalysisSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartData } from '../../features/Product/ProductSlice';

const DurationModal = () => {
  const demandFilter = useSelector((state) => state.demandAnalysis.filter);
  const currentPath = window.location.pathname;
  
  const options = currentPath === '/products' ? [
    { filterType: "Future 2 Weeks", payload: "future_n_weeks&n=2" },
    { filterType: "Future 1 Months", payload: "future_n_months&n=1" },
    { filterType: "Future 3 Months", payload: "future_n_quarters&n=1" },
  ] : [
    { filterType: "Current Year", payload: "current_year" },
    { filterType: "Current Month", payload: "current_month" },
    { filterType: "Past 157 weeks", payload: "past_m_weeks&m=157" },
    { filterType: "Past 6 Months", payload: "past_m_months&m=6" },
    { filterType: "Future 6 Months", payload: "future_n_months&n=6" },
    { filterType: "Past 2 + 1 Future Years", payload: "past_m_future_n_years&m=2&n=1" },
    { filterType: "Past 3 + 1 Future Years", payload: "past_m_future_n_years&m=3&n=1" },
    { filterType: "Past 1 + 1 Future Years", payload: "past_m_future_n_years&m=1&n=1" },
    { filterType: "Past 1 Year", payload: "past_m_years&m=1" },
    { filterType: "Future 1 Year", payload: "future_n_years&n=1" },
  ];

  console.log("currentDemandFilter",demandFilter);

  const currentDemandFilter = demandFilter ? options.map((item) => {
    
    if (item.payload == demandFilter) {
      return item.filterType;
    }
  }) : "Past 1 + 1 Future Years";


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState(
    currentPath === '/products' ? "Future 1 Months" : currentDemandFilter
  );
  
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (filter) => {
    switch (currentPath) {
      case '/demand-analysis':
        dispatch(demandAnalysisData({ filter, filterName: "duration" }));
        dispatch(demandAnalysisPlot({ filter, filterName: "duration" }));
        break;
      case '/products':
        
        dispatch(fetchChartData({ filter, filterName: "duration" }));
        break;
      default:
        console.log("No Path");
        break;
    }

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Define options based on the current path
  

  return (
    <div>
      <Button className="noHover top1_rightbtn" onClick={showModal}>
        <img src={durationIcon} />
        {selectedFilterType}
      </Button>

      <Modal
        className="custom-modal"
        title={
          <div className='Modal_title'>
            <img src={durationIcon} />
            Select Duration
          </div>
        }
        visible={isModalVisible}
        width={800}
        cancelButtonProps={{ style: { color: "red", backgroundColor: "red" } }}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="apply" type="primary" onClick={() => handleOk({ filterType: selectedFilterType, payload: options.find(option => option.filterType === selectedFilterType).payload })}>
            Apply
          </Button>
        ]}
      >
        <div>
          {options.map((option, index) => (
            index % 3 === 0 ? (
              <Row key={index} gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                {options.slice(index, index + 3).map((btn, btnIndex) => (
                  <Col key={btnIndex} span={8}>
                    <Button
                      className='col-btns'
                      style={{ width: '100%' }}
                      onClick={() => {
                        setSelectedFilterType(btn.filterType);
                      }}>
                      {btn.filterType}
                    </Button>
                  </Col>
                ))}
              </Row>
            ) : null
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default DurationModal;
