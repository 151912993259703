import React, { useEffect, useState } from 'react';
import { Card, Table, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SimilarItemsIcon from '../../assets/SVG/icons/Products/SimilarItemsIcon.svg';
import CardsHeading from '../../components/CommonComponents/CardsHeadingComponent';
import { fetchAllItems, fetchAllLocations, fetchExpectedStockout } from '../../features/InventoryInsights/InventoryInsightsSlice';

const { Option } = Select;

function ExpectedStockoutTable() {
  const dispatch = useDispatch();
  const { expectedStockoutData, allItemsData, allLocationsData, stockoutloading, error } = useSelector((state) => state.inventoryInsights);
  const [filteredData, setFilteredData] = useState([]);
  const [itemFilter, setItemFilter] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item_sku',
      key: 'item_sku',
      onHeaderCell: () => ({
        style: { color: '#919191' }
      }),
      render: (text) => <div style={{ color: '#7B61FF' }}>{text}</div>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      onHeaderCell: () => ({
        style: { color: '#919191' }
      }),
      render: (text) => <div style={{ color: '#7B61FF' }}>{text}</div>,
    },
    {
      title: 'Expected Stock Out',
      dataIndex: 'expected_stockout_week',
      key: 'expected_stockout_week',
      onHeaderCell: () => ({
        style: { color: '#919191' }
      }),
      render: (text) => (
        <div style={{ color: '#7B61FF' }}>{text}</div>
      ),
    },
  ];

  const staticData = [
    {
      id: 1,
      item_sku: '-',
      location: '-',
      expected_stock_out: '-',
    },
  ];

  useEffect(() => {
    dispatch(fetchAllItems());
  }, [dispatch]);

  useEffect(() => {
    if (allItemsData?.items?.length > 0 && !itemFilter) {
      setItemFilter(allItemsData.items[0]); // Set default item
    }
  }, [allItemsData, itemFilter]);

  useEffect(() => {
    if (itemFilter) {
      dispatch(fetchAllLocations({ item_sku: itemFilter }));
    }
  }, [itemFilter, dispatch]);

  useEffect(() => {
    if (itemFilter || locationFilter) {
      dispatch(fetchExpectedStockout({ item_sku: itemFilter || "", location: locationFilter || "" }));
    }
  }, [itemFilter, locationFilter, dispatch]);

  useEffect(() => {
    setFilteredData(expectedStockoutData || []);
  }, [expectedStockoutData]);

  const handleChange = (value, type) => {
    if (type === 'item') {
      setItemFilter(value);
      if (value) {
        dispatch(fetchExpectedStockout({ item_sku: value, location: locationFilter }));
      } else {
        // Clear location filter if item filter is cleared
        setLocationFilter(null);
        dispatch(fetchExpectedStockout({ item_sku: "", location: "" }));
      }
    } else if (type === 'location') {
      setLocationFilter(value);
      if (itemFilter) {
        dispatch(fetchExpectedStockout({ item_sku: itemFilter, location: value }));
      }
    }
  };

  return (
    <Card className='dashboard_card_containers' style={{ marginBottom: "20px" }}>
      <div className='forecasted_data_main'>
        <CardsHeading
          heading="Expected Stock Out"
          headingicon={SimilarItemsIcon}
          dataTODownload={expectedStockoutData}
          showFilter={false}
          componentType="expectedstockout"
        />
        {stockoutloading ? (
          <Spin size="large" />
        ) : (
          <div>
            <Select
              style={{ width: 200, marginBottom: 16, margin: 10 }}
              placeholder="Select Item"
              onChange={(value) => handleChange(value, 'item')}
              showSearch
              optionFilterProp="children"
              value={itemFilter || undefined} // Ensure value is undefined if itemFilter is null
            >
              {(allItemsData?.items || []).map(item => (
                <Option key={item} value={item}>{item}</Option>
              ))}
            </Select>
            <Select
              style={{ width: 200, marginBottom: 16, margin: 10 }}
              placeholder="Select Location"
              onChange={(value) => handleChange(value, 'location')}
              allowClear
              showSearch
              optionFilterProp="children"
              value={locationFilter || undefined} // Ensure value is undefined if locationFilter is null
            >
              {(allLocationsData?.location || []).map(location => (
                <Option key={location} value={location}>{location}</Option>
              ))}
            </Select>

            <Table
              dataSource={filteredData.length > 0 ? filteredData : staticData}
              columns={columns}
              pagination={{ pageSize: 5, showSizeChanger: false, hideOnSinglePage: true }}
              rowKey="id"
            />
          </div>
        )}
      </div>
    </Card>
  );
}

export default ExpectedStockoutTable;
