import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

const buildQueryParams = (state, globalFilters) => {
    let queryParams = [];
    console.log("state", state);

    // Loop through globalFilters dynamically
    for (const [key, value] of Object.entries(globalFilters)) {
        if (Array.isArray(value)) {
            // Handle array values by appending each item with the corresponding key
            queryParams.push(...value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`));
        } else {
            // If it's not an array, just append the key and value pair
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
    }

    // Append levelFilter if it exists
    if (state.levelFilter) {
        queryParams.push(`level=${encodeURIComponent(state.levelFilter.toLowerCase())}`);
    }

    // Append productFilter if it exists
    if (state.productFilter) {
        queryParams.push(`filter_type=${state.productFilter}`);
    }

    // Join the parameters into a single query string
    return queryParams.length > 0 ? queryParams.join('&') : '';
};



export const fetchSimilarItems = createAsyncThunk(
    'products/fetchSimilarItems',
    async (params, thunkAPI) => {
        let endpoint = "";
        let joint = "";
        console.log("dhjbdksj",params.filterName); // Debug log

        switch(params.filterName) {
            case "items":
                thunkAPI.dispatch(setItemFilter(params.selectedItems));
                break;
            case "location":
                thunkAPI.dispatch(setLocationFilter(params.selectedItems));
                break;
            default:
                thunkAPI.dispatch(setFilter('future_n_years&n=1'));
                thunkAPI.dispatch(setItemFilter([]));
                thunkAPI.dispatch(setLocationFilter([]));
                break;  
        }

        const state = thunkAPI.getState();
        const globalFilter = state.filter.globalFilters
        joint = buildQueryParams(state.products, globalFilter); // Do not include levelFilter
        endpoint = `customer/similar-items/?${joint}`;
        console.log(`Fetching similar items with endpoint: ${endpoint}`); // Debug log
        const request = await makeRequest("get", endpoint);
        return request.data;
    }
);

export const fetchChartData = createAsyncThunk(
    'products/fetchChartData',
    async (params, thunkAPI) => {
        let endpoint = "";
        let joint = ""; 

        switch(params.filterName) {
            case "duration":
                thunkAPI.dispatch(setFilter(params.filter.payload));
                break;
            case "product":
                thunkAPI.dispatch(setItemFilter(params.selectedItems));
                break;
            case "location":
                thunkAPI.dispatch(setLocationFilter(params.selectedItems));
                break;
            case "level":
                thunkAPI.dispatch(setLevelFilter(params.filter.filterType));
                break;
            default:
                thunkAPI.dispatch(setFilter('future_n_months&n=1'));
                thunkAPI.dispatch(setItemFilter([]));
                thunkAPI.dispatch(setLocationFilter([]));
                break;

        }

        const state = thunkAPI.getState();
        const globalFilter = state.filter.globalFilters
        joint = buildQueryParams(state.products, globalFilter); // Include levelFilter
        endpoint = `customer/product-forecast-trend/?${joint}`;
        //console.log(`Fetching chart data with endpoint: ${endpoint}`); // Debug log
        //console.log(`Query parameters: ${joint}`); // Debug log
        const request = await makeRequest("get", endpoint);
        //console.log(`API Response: ${request.data}`); // Debug log
        return request.data;
    }
);

const productSlice = createSlice({
    name: "products",
    initialState: {
        status: 'idle',
        similarItems: [],
        chartData: [],
        itemFilter: ["All"],
        locationFilter: ["All"],
        selectedItem: [],
        selectedLocation: [],
        selectedLevel: "Monthly",
        levelFilter: "Monthly",
        productFilter: ['All'],
    },
    reducers: {
        setItemFilter: (state, action) => {
            state.itemFilter = action.payload;
        },
        setFilter: (state, action) => {
            state.productFilter = action.payload;
        },
        setLocationFilter: (state, action) => {
            state.locationFilter = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload;
        },
        setLevelFilter: (state, action) => {
            state.levelFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSimilarItems.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSimilarItems.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.similarItems = action.payload;
            })
            .addCase(fetchSimilarItems.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchChartData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchChartData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.chartData = action.payload;
            })
            .addCase(fetchChartData.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setFilter, setItemFilter, setLocationFilter, setSelectedItem, setSelectedLocation, setLevelFilter } = productSlice.actions;

export default productSlice.reducer;
