import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

// Initial state for the slice
const initialState = {
  expectedStockoutData: [],
  inventoryInsightsData: [],
  opportunityLostData: [],
  allItemsData: [],
  allLocationsData: [],
  loading: false,
  stockoutloading: false,
  insightloading: false,
  error: null,
};

// helper function to build query parameter for dictionary to get url parameters only
const dictToUrlParams = (params) => {
  if (!params || typeof params !== 'object') {
    return ''; // Return an empty string if params is undefined, null, or not an object
  }
  
  const queryString = Object.keys(params)
    .filter(key => params[key] !== null && params[key] !== undefined) // Filter out null and undefined values
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])) // Add '?' before each key
    .join('&'); // Join with '&' to create the query string

  return '?'+queryString; // Return the complete query string
};

// Helper function to build query parameters
const buildQueryParams = (state, globalFilters) => {
  let queryParams = [];

  // Loop through globalFilters dynamically
  for (const [key, value] of Object.entries(globalFilters)) {
    if (Array.isArray(value)) {
      queryParams.push(...value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item.toLowerCase())}`));
    } else {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toLowerCase())}`);
    }
  }

  if (state.genderFilter && state.genderFilter.length > 0 && state.genderFilter[0] !== "All") {
    queryParams.push(...state.genderFilter.map(item => `gender=${encodeURIComponent(item.toLowerCase())}`));
  }

  if (state.levelFilter) {
    queryParams.push(`level=${encodeURIComponent(state.levelFilter.toLowerCase())}`);
  }

  return queryParams.join('&');
};

// Thunk for fetching opportunity lost data with switch and thunkAPI
export const fetchOpportunityLost = createAsyncThunk(
  'inventoryInsights/fetchOpportunityLost',
  async ({ item_sku, location }, thunkAPI) => {
    const queryParams = dictToUrlParams({ item_sku, location });
    const endpoint = `customer/chart_opportunity_lost/${queryParams}`;
    const response = await makeRequest('get', endpoint);

    return response.data;
  }
);


// Existing thunks for expected stockout and inventory insights
export const fetchExpectedStockout = createAsyncThunk(
  'inventoryInsights/fetchExpectedStockout',
  async (parameter) => {
    const url_parameters=dictToUrlParams(parameter)
    console.log("url_parameters",url_parameters);
    const response = await makeRequest('get', `customer/expected_stockout/${url_parameters}`);
    return response.data; 
  }
);

export const fetchAllItems = createAsyncThunk(
  'inventoryInsights/fetchAllItems',
  async () => {
    const response = await makeRequest('get', 'customer/all_items/');
    return response.data; 
  }
);

export const fetchAllLocations = createAsyncThunk(
  'inventoryInsights/fetchAllLocations',
  async (parameter) => {
    const url_parameters=dictToUrlParams(parameter)
    console.log("url_parameters",url_parameters);
    const response = await makeRequest('get', `customer/get_locations/${url_parameters}`);
    return response.data; 
  }
);


export const fetchInventoryInsights = createAsyncThunk(
  'inventoryInsights/fetchInventoryInsights',
  async (parameter) => {
    const url_parameters=dictToUrlParams(parameter)
    console.log("url_parameters",url_parameters);
    const response = await makeRequest('get', `customer/inventory_insights/${url_parameters}`);
    return response.data;
  }
);

const inventoryInsightsSlice = createSlice({
  name: 'inventoryInsights',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setBrandFilter: (state, action) => {
      state.brandFilter = action.payload;
    },
    setCountryFilter: (state, action) => {
      state.countryFilter = action.payload;
    },
    setLevelFilter: (state, action) => {
      state.levelFilter = action.payload;
    },
    setCategoryFilter: (state, action) => {
      state.categoryFilter = action.payload;
    },
    setGenderFilter: (state, action) => {
      state.genderFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpectedStockout.pending, (state) => {
        state.stockoutloading = true;
      })
      .addCase(fetchExpectedStockout.fulfilled, (state, action) => {
        state.stockoutloading = false;
        state.expectedStockoutData = action.payload;
      })
      .addCase(fetchExpectedStockout.rejected, (state, action) => {
        state.stockoutloading = false;
        state.error = action.error.message; 
      });

    builder
      .addCase(fetchInventoryInsights.pending, (state) => {
        state.insightloading = true;
      })
      .addCase(fetchInventoryInsights.fulfilled, (state, action) => {
        state.insightloading = false;
        state.inventoryInsightsData = action.payload;
      })
      .addCase(fetchInventoryInsights.rejected, (state, action) => {
        state.insightloading = false;
        state.error = action.error.message; 
      });

    builder
      .addCase(fetchOpportunityLost.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOpportunityLost.fulfilled, (state, action) => {
        state.loading = false;
        state.opportunityLostData = action.payload;
      })
      .addCase(fetchOpportunityLost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; 
      });

    builder
      .addCase(fetchAllItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllItems.fulfilled, (state, action) => {
        state.loading = false;
        state.allItemsData = action.payload;
      })
      .addCase(fetchAllItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; 
      });

    builder
      .addCase(fetchAllLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.allLocationsData = action.payload;
      })
      .addCase(fetchAllLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; 
      });

      
  },
});

export const { setFilter, setBrandFilter, setCountryFilter, setLevelFilter, setCategoryFilter, setGenderFilter } = inventoryInsightsSlice.actions;

export default inventoryInsightsSlice.reducer;
