import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';
import PieChart from './PieChart';
import LineChart from './LineChart';
import BarChart from './BarChart';

const FullScreenModal = ({ visible, fullscreenChart, handleFullscreen }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3; // Set the maximum number of retries

  useEffect(() => {
    if (visible && fullscreenChart) {
      loadChartData();
    }
  }, [visible, fullscreenChart, retryCount]); // Retry when retryCount changes

  const loadChartData = async () => {
    setLoading(true);
    setError(false);
    
    try {
      // Simulate a delay and potential error
      await new Promise((resolve, reject) => {
        const hasError = Math.random() < 0.3; // 30% chance to simulate an error
        setTimeout(() => (hasError ? reject(new Error('Failed to load')) : resolve()), 1000);
      });
    } catch {
      if (retryCount < maxRetries) {
        setRetryCount(retryCount + 1); // Increment retry count
      } else {
        setError(true); // Set error if max retries exceeded
      }
    } finally {
      setLoading(false);
    }
  };

  const retryLoad = () => {
    setRetryCount(0); // Reset retry count to 0 for the next attempt
    loadChartData(); // Attempt to load the data again
  };

  const sortedChartData = fullscreenChart?.chart_data
    ? [...fullscreenChart.chart_data].sort((a, b) => {
        if (fullscreenChart.chart_type.toLowerCase() === "pie_chart") {
          return b.value - a.value; // Descending by label for PieChart
        } else {
          return b.x_axis - a.x_axis; // Descending by x_axis for BarChart
        }
      })
    : [];

  return (
    <Modal
      visible={visible}
      footer={null}
      width="60%"
      onCancel={() => handleFullscreen(null)}
      centered
      bodyStyle={{ height: '70vh' }}
    >
      {loading && <Spin size="large" />}
      {error && (
        <div>
          <p>Error loading chart data. Please try again.</p>
          <button onClick={retryLoad}>Retry</button>
        </div>
      )}
      {fullscreenChart && !loading && !error && (
        <div className="chart-fullscreen-body">
          {fullscreenChart.chart_type.toLowerCase() === "bar_chart" && (
            <BarChart
              row={sortedChartData.map((item) => item.x_axis)}
              col={sortedChartData.map((item) => item.y_axis)}
              x_axis_label={fullscreenChart.parameter_1.replace(/_/g, ' ')}
              y_axis_label={fullscreenChart.parameter_2.replace(/_/g, ' ')}
            />
          )}
          {fullscreenChart.chart_type.toLowerCase() === "line_chart" && (
            <LineChart
              row={sortedChartData.map((item) => item.x_axis)}
              col={sortedChartData.map((item) => item.y_axis)}
              x_axis_label={fullscreenChart.parameter_1.replace(/_/g, ' ')}
              y_axis_label={fullscreenChart.parameter_2.replace(/_/g, ' ')}
            />
          )}
          {fullscreenChart.chart_type.toLowerCase() === "pie_chart" && (
            <PieChart
              row={sortedChartData.map((item) => item.label)}
              col={sortedChartData.map((item) => item.value)}
              values_label={fullscreenChart.parameter_2.replace(/_/g, ' ')}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default FullScreenModal;
