import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import Objects from '../../assets/Images/Objects.png';
import EmptyState from '../../components/CommonComponents/EmptyStateComponent';
import MyChartsComponents from '../../components/MyChartsComponents/MyChartsComponents'; // Ensure the correct import
import { fetchMyChartData,fetchDropdownValuesAddChart } from '../../features/MyCharts/MyChartsSlice';
import '../../css/MyCharts/myCharts.css';

function MyChartPage() {
    const dispatch = useDispatch();
    const { myChartData, refreshStatus, status } = useSelector((state) => state.myCharts || {});

    const [selectedChart, setSelectedChart] = useState(null);
    const [chartData, setChartData] = useState({ row: [], col: [] });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Dispatch both actions
        const fetchData = async () => {
            await dispatch(fetchMyChartData());
            await dispatch(fetchDropdownValuesAddChart());
            setIsLoading(false); // Set loading to false once both are done
        };
    
        if (refreshStatus == 'succeeded') {
            fetchData();
        }
    }, [refreshStatus]); 


    useEffect(() => {
        // Only triggered when dispatch is available
        const fetchDataOnDispatch = async () => {
            await dispatch(fetchMyChartData());
            await dispatch(fetchDropdownValuesAddChart());
            setIsLoading(false); // Set loading to false once both are done
        };
    
        fetchDataOnDispatch();
    }, [dispatch]); // Dependency on dispatch
    
    // useEffect(() => {
    //     if (selectedChart && selectedChart.SelectDataSource && selectedChart["select chart type"]) {
    //         // const params = {
    //         //     dataset: selectedChart.SelectDataSource,
    //         //     chart_type: selectedChart["select chart type"],
    //         // };
    //         dispatch(fetchMyChartData());
    //     }
    // }, [selectedChart, dispatch]);

    // const handleCreateChart = (chartData) => {
    //     setSelectedChart(chartData);
    // };

    // const extractDataForChart = (inputArr) => {
    //     if (!Array.isArray(inputArr)) return;

    //     const rows = [];
    //     const cols = [];
    //     const monthsToShow = ['Jan', 'May', 'Sep'];
    //     const monthIndices = [0, 4, 8];

    //     const aggregatedData = {};

    //     inputArr.forEach(element => {
    //         if (selectedChart["select chart type"] === 'line chart') {
    //             const date = new Date(element.date);
    //             const month = date.getMonth();
    //             const year = date.getFullYear();
    //             const monthName = date.toLocaleString('default', { month: 'short' });

    //             if (monthIndices.includes(month)) {
    //                 const key = `${monthName} ${year}`;
    //                 if (!aggregatedData[key]) {
    //                     aggregatedData[key] = 0;
    //                 }
    //                 aggregatedData[key] += element.total_sales;
    //             }
    //         } else if (selectedChart["select chart type"] === 'bar chart') {
    //             rows.push(element.brand);
    //             cols.push(element.total_sales);
    //         } else if (selectedChart["select chart type"] === 'pie chart') {
    //             rows.push(element.category);
    //             cols.push(element.sales_percentage);
    //         }
    //     });

    //     for (const [key, value] of Object.entries(aggregatedData)) {
    //         rows.push(key);
    //         cols.push(value);
    //     }

    //     setChartData({ row: rows, col: cols });
      
    // };

    // useEffect(() => {
    //     if (myChartData && myChartData.length > 0 && selectedChart) {
    //         extractDataForChart(myChartData);
    //     }
    // }, [myChartData, selectedChart]);

    return (
        <div className='dashboard_main_container'>
            
            {isLoading ? (
                <Spin/>
            ) :(
                 <>
                     {myChartData != null && myChartData?.length > 0 ? (
                        <MyChartsComponents 
                            myChartData={myChartData} 
                        />    
                    
                    ) : (
                        <EmptyState 
                            img={Objects} 
                            data={(
                                <>
                                    Please create a new chart from the data 
                                    <br />
                                    available to view here.
                                </>
                            )} 
                            page="MyChart"  
                        />
                    )} 
                </>
            )} 
        </div>
    );
}

export default MyChartPage;
