import React from 'react';
import Chart from 'react-apexcharts';
import '../../css/BarChart.css'; // Import the external CSS file


function BarChart({ row, col, x_axis_label, y_axis_label }) {
    const capitalizeWords = (text) => {
        return text.split(' ').map(word => {
            return word.length > 2 ? word.charAt(0).toUpperCase() + word.slice(1) : word;
        }).join(' ');
    };

    const formatNumber = (num) => {
        if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + 'M';
        if (num >= 1_000) return (num / 1_000).toFixed(1) + 'K';
        return num;
    };

    const options = {
        chart: {
            type: 'bar',
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: col, // This will be your brand names
            title: {
                text: capitalizeWords(x_axis_label), // Capitalize x-axis label
                style: {
                    fontWeight: '400', // Reduced font weight
                    color: "#7B61FF",
                    fontSize: '14px',
                }
            },
            labels: {
                formatter: (value) => formatNumber(value),
                style: {
                    color: "#7B61FF", // Set color for x-axis labels
                    fontSize: '12px',
                }
            },
        },
        yaxis: {
            title: {
                text: capitalizeWords(y_axis_label), // Capitalize y-axis label
                style: {
                    fontWeight: '400', // Reduced font weight
                    color: "#7B61FF",
                    fontSize: '14px',
                },
            },
            labels: {
                formatter: (value) => formatNumber(value), // Formatting the label if needed
                style: {
                    color: "#7B61FF", // Set color for x-axis labels
                    fontSize: '12px',
                }
            }
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (value) => `${value}`, // Formatting the tooltip if needed
            }
        }
    };

    // Ensure row (series) is in the correct format
    const series = [
        {
            name: capitalizeWords(y_axis_label), // Series name to display meaningful label
            data: row, // Your total sales data
        }
    ];


    return (
        <div className='bar-chart'>
            <Chart options={options} series={series} type="bar" width="100%" />
        </div>
    );
}

export default BarChart;
