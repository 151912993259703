import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'antd';
import CardsHeading from '../../CommonComponents/CardsHeadingComponent';
import ForecastGraphFilters from './ForecastGraphFilters';
import Graph from './ForecastedMainGraph';
import '../../../css/DemandAnalysis/graphDemand.css';
import forecastedchartIcon from '../../../assets/SVG/icons/DemandAnalysis/forecastedcharticon.svg';
import { useDispatch, useSelector } from "react-redux";

function ForecastedChart() {
    const { forecastDemandAnalysisPlot } = useSelector((state) => state.demandAnalysis || {});
    const [loading, setLoading] = useState(true);
    const chartRef = useRef(null);
    const [highlightedDay, setHighlightedDay] = useState(null);
    const [holidayList, setHolidayList] = useState([]);

    useEffect(() => {
        if (forecastDemandAnalysisPlot?.actual) {
            const holidaysSet = new Set();
            forecastDemandAnalysisPlot.actual.forEach(entry => {
                if (entry.is_holiday) {
                    holidaysSet.add(entry.holiday_name);
                }
            });
            const holidaysArray = Array.from(holidaysSet);
            setHolidayList(holidaysArray);
        }
    }, [forecastDemandAnalysisPlot]);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    const handleSpecialDayHover = (day) => {
        setHighlightedDay(day);
    };

    return (
        <Card
            className='dashboard_card_containers'
            loading={loading}
            style={{ marginBottom: "20px" }}
        >
            <CardsHeading
                heading="Forecasted Chart"
                headingicon={forecastedchartIcon}
                dataTODownload={chartRef}
                showFilter={true}
                componentType="forecastedChart"
            />

            <div ref={chartRef} style={{ backgroundColor: 'white' }}>
                <Graph highlightedDay={highlightedDay} />
            </div>
            <ForecastGraphFilters handleSpecialDayHover={handleSpecialDayHover} holidayList={holidayList} />
        </Card>
    );
}

export default ForecastedChart;
