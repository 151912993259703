import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import { Switch, Button, message, Avatar } from "antd";
import { fetchUsers, deleteUser, userActiveStatus } from "../../features/UseMangementSlice";
import AddUser from "../../Modal/AddUser";
import UserTable from "./UserTable";
import DeleteConfirmationModal from '../../Modal/DeleteConfirmationModal';
import userIcon from "../../assets/SVG/Navbar/Usericon.svg";
import adminIcon from "../../assets/SVG/UserManagement/admin.svg";
import memberIcon from "../../assets/SVG/UserManagement/member.svg";
import onboardingBin from "../../assets/Images/OnboardingBin.png";
import "../../css/UserManagementArea.css";  // Import the CSS file

function UserManagementArea() {
  const dispatch = useDispatch();
  const { users, isActiveStatus } = useSelector((state) => ({
    users: state.userManagement.users,
    isActiveStatus: state.userManagement.isActiveStatus,
  }));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [visible, setVisible] = useState(false);
  const [usernameDelete, setUsernameDelete] = useState(null);

  const handleSwitchChange = (checked, record) => {
    dispatch(userActiveStatus(record.id));
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    if (isActiveStatus === 'succeeded') {
      message.success('User status changed successfully!', 3);
      dispatch(fetchUsers());
    }
  }, [isActiveStatus, dispatch]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    dispatch(fetchUsers({ search: searchValue, page: pagination.current }));
  };

  const handleDelete = (username) => {
    setUsernameDelete(username);
    setRecordToDelete(username);
    setIsModalVisible(true);
  };

  const handleDeleteOk = () => {
    if (recordToDelete) {
      dispatch(deleteUser({ username: recordToDelete }))
        .then(() => {
          message.success("User deleted successfully.", 3);
          dispatch(fetchUsers({ search: searchValue, page: pagination.current }))
            .then((response) => {
              if (response?.data?.length === 0 && pagination.current > 1) {
                setPagination({
                  ...pagination,
                  current: pagination.current - 1,
                });
                dispatch(fetchUsers({ search: searchValue, page: pagination.current - 1 }));
              }
            })
            .catch((error) => {
              console.error('Failed to fetch users after delete:', error);
            });
        })
        .catch((error) => {
          console.error('Failed to delete user:', error);
        });
    }
    setIsModalVisible(false);
    setRecordToDelete(null);
  };

  const handleDeleteCancel = () => {
    setIsModalVisible(false);
    setRecordToDelete(null);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
      render: (text, record) => (
        <div className="user-avatar">
          <Avatar
            onClick={() => console.log(record.profile_pic_url)}
            src={record.profile_pic_url}
          />
          {text}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      className: "column-padding",
      render: (text, record) => (
        <div className="role-icon">
          <img
            onClick={() => console.log(record.role)}
            src={record.role === "Admin" ? adminIcon : memberIcon}
            alt="Role Icon"
            className="role-image"
          />
          {text}
        </div>
      ),
    },
    {
      title: "Active / Inactive",
      dataIndex: "is_active",
      key: "is_active",
      className: 'column-padding onboarding_page_is_acive',
      render: (_, record) => (
        <Switch
          checked={record.is_active}
          onChange={(checked) => handleSwitchChange(checked, record)}
        />
      ),
    },
    {
      title: "",
      dataIndex: "bin",
      key: "bin",
      render: (_, record) => (
        <Button className="purple-row" onClick={() => handleDelete(record.username)}>
          <img
            src={onboardingBin}
            alt="Bin Btn"
            className="bin-image"
          />
        </Button>
      ),
    }
  ];

  const data = Array.isArray(users) ? users
  .filter(user => user !== undefined) // Remove undefined entries from the array
  .map((user) => {
    const cleanedUser = {
      id: user.id,
      username: user.name,
      email: user.user_email,
      contact_number: user.contact ? user.contact : '  -  ',
      customer_name: user.customer_name ? user.customer_name : '  -  ',
      role: user.role ? user.role : '  -  ',
      is_active: user.is_active,
      profile_pic_url: user.profile_img || userIcon,
    };

    // Remove undefined properties
    return Object.fromEntries(Object.entries(cleanedUser).filter(([_, value]) => value !== undefined));
  })
: [];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleSearch = () => {
    dispatch(fetchUsers({ search: searchValue }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  return (
    <div className="uma_main">
      <div className="uma_container">
        <div className="uma_elem1">
          <div className="uma_elem1_left">
            <span>Total User ({data.length})</span>
          </div>
          <div className="uma_elem1_right">
            <div className="uma_search_box">
              <div className="uma-search-bar">
                <input
                  className="uma-search-input"
                  type="text"
                  placeholder="Search here..."
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <Search className="uma-search-icon" onClick={handleSearch} />
              </div>
            </div>
            <div>
              <Button
                type="primary"
                className="add-user-button"
                onClick={showModal}
              >
                ADD
              </Button>
            </div>
          </div>
        </div>

        <div className="uma_elem2">
          <UserTable
            columns={columns}
            data={data}
            pagination={pagination}
            rowSelection={rowSelection}
            onChange={handleTableChange}
          />
          
          <DeleteConfirmationModal
            visible={isModalVisible}
            onOk={handleDeleteOk}
            onCancel={handleDeleteCancel}
            message={`Are you sure you want to delete user ${usernameDelete}?`}
          />
        </div>
      </div>

      <AddUser
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </div>
  );
}

export default UserManagementArea;
