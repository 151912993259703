import React, { useState, useRef, useEffect } from 'react'; 
import { Modal, Input, Button, Form, Select, Radio, Space, ConfigProvider } from 'antd';
import BarChartImage from '../assets/Images/BarChart.png'; 
import LineChartImage from '../assets/Images/LineChart.png'; 
import PieChartImage from '../assets/Images/PieChart.png'; 
import Group66 from '../assets/Images/Group66.png'; 
import ChartSettingsModal from './ChartSettingsModal'; 
import { useDispatch,useSelector } from 'react-redux';
import { fetchDropdownValuesAddChart } from '../features/MyCharts/MyChartsSlice';

const { Option } = Select;

function AddChartModal({ visible, onCancel }) {
    const [selectedChartType, setSelectedChartType] = useState(null);
    const [selectedDataSource, setSelectedDataSource] = useState(null);
    const [selectedChartName, setSelectedChartName] = useState(null);
    const [isChartModalVisible, setIsChartModalVisible] = useState(false);
    const dispatch = useDispatch();
    const { myChartDropdownValues } = useSelector((state) => state.myCharts);

    const chartTypes = myChartDropdownValues.chart_type.map((chart) => ({
        value: chart, // Keep the value as is from the backend
        label: chart
          .replace('_', ' ')
          .replace(/\b\w/g, (c) => c.toUpperCase()), // Format label to capitalize words
        image: chart === 'bar_chart' 
          ? BarChartImage 
          : chart === 'line_chart' 
          ? LineChartImage 
          : chart === 'pie_chart' 
          ? PieChartImage 
          : null, // Dynamically assign images based on chart type
      }));
      console.log("myChartDropdownValues",myChartDropdownValues)

    const data_set = myChartDropdownValues.data_source.map((data) => {
        const formattedData = data.replace(/_/g, ' ');
        const capitalizedData = formattedData.charAt(0).toUpperCase() + formattedData.slice(1);
        
        return (
            <Option key={data} value={data}>
                {capitalizedData}
            </Option>
        );
    });
    

      const SelectChartType = ({ value, selectedChartType, onChange }) => (
        <Radio.Button 
            value={value} 
            className={`radioButtonStyle ${selectedChartType === value ? 'selectedChart' : ''}`}
            onClick={() => onChange(value)}
        >
            <div className='backgroundStyle'></div>
            <img src={chartTypes.find(chart => chart.value === value).image} alt={value} className='imageStyle' />
            <span className={`chart-label ${selectedChartType === value ? 'selectedChartLabel' : ''}`}>
                {chartTypes.find(chart => chart.value === value).label}
            </span>
        </Radio.Button>
    );

    

    const formRef = useRef(null);

    const handleChartTypeChange = (value) => {
        setSelectedChartType(value);
    };

    const handleDataSourceTypeChange = async (value) =>{
        setSelectedDataSource(value);
    }
    

    const handleNext = async () => {
        try {
            
            await formRef.current.validateFields();
            if (selectedChartType) {
                dispatch(fetchDropdownValuesAddChart({"data_source":selectedDataSource,"chart_type":selectedChartType}));
                setIsChartModalVisible(true);
            }
        } catch (error) {
            console.error("Validation Failed:", error);
        }
    };


    const handleCloseChartModal = () => {
        onCancel();
        setIsChartModalVisible(false);
    };

    const handleBackChartModal = () => {
        setIsChartModalVisible(false);
    };

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: '#F6F5FF',
                },
            }}
        >
            <div className='my-charts-modal'>
                <Modal
                    visible={visible}
                    className='my-charts-modal-container'
                    centered
                    title={(
                        <span className='modal-title'>
                            <img className='chart-icon' src={Group66} alt="Chart Icon" />
                            <div className='modal-header-content'>
                                <div className='my-charts-header'>Creating Chart - First Step</div>
                                <p className='my-charts-sub-header'>
                                    Design a chart tailored to your needs to monitor the specific data 
                                    <br/>
                                    you want to track.
                                </p>
                            </div>
                        </span>
                    )}
                    onCancel={onCancel}
                    footer={<>
                        <Button onClick={onCancel} className='cancel-button'>Cancel</Button>
                        <Button onClick={handleNext} className='next-button'type="primary">Next</Button>
                    </>}
                >
                    <Form ref={formRef} layout="vertical">
                        <Form.Item
                            name="ChartTitle"
                            label={<span className='labelStyle'>Chart Title</span>}
                            className='formItemStyle'
                            rules={[{ required: true, message: 'Please input the Chart title!' }]}
                            
                        >
                            <Input 
                                placeholder="Enter title here" 
                                onChange={(e) => setSelectedChartName(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item
                            name="SelectDataSource"
                            label={<span className='labelStyle'>Data Set</span>}
                            className='formItemStyle'
                        >
                            <p className='data-source-description'>
                                Create a chart using Sales, Forecast, Inventory, and Sales Assortments data.
                            </p>
                            <Select 
                                placeholder="Sales"
                                onChange={handleDataSourceTypeChange}
                            >
                                {data_set}
                            </Select>
                        </Form.Item>

                        <span className='labelStyle'>Chart Type</span>

                        <p className='chart-type-description'>
                            Select a chart type: bar, line, or pie. Modify details in the next step.
                        </p>

                        <Form.Item
                            name="ChartType"
                            rules={[{ required: true, message: 'Please select a chart type' }]}
                            className='formItemStyle'
                        >
                            <Radio.Group className='radio-group'>
                                <Space size="large" wrap>
                                    {chartTypes.map((chart) => (
                                        <SelectChartType 
                                            key={chart.value}
                                            value={chart.value} 
                                            selectedChartType={selectedChartType} 
                                            onChange={handleChartTypeChange}
                                        />
                                    ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>

                {selectedChartType && (
                    <ChartSettingsModal
                        visible={isChartModalVisible}
                        onCancel={handleCloseChartModal}
                        onBack={handleBackChartModal}
                        chartType={selectedChartType}
                        chartName={selectedChartName}
                        dataSource={selectedDataSource}
                    />
                )}
            </div>
        </ConfigProvider>
    );
}

export default AddChartModal;
