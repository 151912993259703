import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const fetchMyChartData = createAsyncThunk(
    'myCharts/fetchMyChartData',
    async (_, thunkAPI) => {
        try {
            const endpoint = `customer/all_charts/`;
            const request = await makeRequest("get", endpoint);
            return request.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data || 'An error occurred');
        }
    }
);

export const fetchDropdownValuesAddChart = createAsyncThunk(
    'myCharts/fetchDropdownValuesAddChart',
    async (param, thunkAPI) => {
        try {
            const queryString = new URLSearchParams(param).toString();
            const endpoint = `customer/dropdown-values/?${queryString}`;
            const request = await makeRequest("get", endpoint);
            return request.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data || 'An error occurred');
        }
    }
);

export const createChart = createAsyncThunk(
    'myCharts/createChart',
    async (param, thunkAPI) => {
        try {
            const endpoint = `customer/create_chart/`;
            const request = await makeRequest("post", endpoint, param);
            return request.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data || 'An error occurred');
        }
    }
);

export const deleteChart = createAsyncThunk(
    'myCharts/deleteChart',
    async (chartId, thunkAPI) => {
        const endpoint = `customer/delete_chart/${chartId}/`;
        await makeRequest("delete", endpoint);
        return chartId; 
    }
);

export const refreshChart = createAsyncThunk(
    'myCharts/refreshChart',
    async (chartId, thunkAPI) => {
        const endpoint = `customer/refresh_chart/${chartId}/`;
        const request = await makeRequest("put", endpoint);
        return request.data;
    }
);

const MyChartsSlice = createSlice({
    name: "myCharts",
    initialState: {
        status: 'idle',
        chartCreationStatus: 'idle',
        refreshStatus: 'idle',
        chartCreationError: null,
        myChartData: [],
        myChartDropdownValues: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyChartData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMyChartData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.myChartData = action.payload;
            })
            .addCase(fetchMyChartData.rejected, (state) => {
                state.status = 'failed'; // Store the error
            });

        builder
            .addCase(fetchDropdownValuesAddChart.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDropdownValuesAddChart.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.myChartDropdownValues = action.payload;
            })
            .addCase(fetchDropdownValuesAddChart.rejected, (state) => {
                state.status = 'failed';// Store the error
            });

        builder
            .addCase(createChart.pending, (state) => {
                state.status = 'loading';
                state.chartCreationStatus = 'loading';
            })
            .addCase(createChart.fulfilled, (state) => {
                state.status = 'succeeded';
                state.chartCreationStatus = 'succeeded';
            })
            .addCase(createChart.rejected, (state, action) => {
                state.status = 'failed';
                state.chartCreationStatus = 'failed';
                state.chartCreationError = action.payload.error; // Store the error
            });
        
        builder
            .addCase(deleteChart.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteChart.fulfilled, (state, action) => {
                state.myChartData = state.myChartData.filter(chart => chart.chart_id !== action.payload);
            })
            .addCase(deleteChart.rejected, (state, action) => {
                state.status = 'failed';
            });
        builder
            .addCase(refreshChart.pending, (state) => {
                state.refreshStatus = 'loading';
            })
            .addCase(refreshChart.fulfilled, (state, action) => {
                state.refreshStatus = 'succeeded';
            })
            .addCase(refreshChart.rejected, (state) => {
                state.refreshStatus = 'failed';
            })

    },
});

export const selectMyChartData = (state) => state.myCharts.myChartData;

export default MyChartsSlice.reducer;
