import React, { useState, useEffect } from 'react';   
import Chart from 'react-apexcharts';
import { Spin, Select, Card } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import FullscreenModal from '../CommonComponents/FullscreenModal';
import { fetchOpportunityLost, fetchAllItems, fetchAllLocations } from '../../features/InventoryInsights/InventoryInsightsSlice'; 

const { Option } = Select;

function OpportunityLostPlot() {
    const dispatch = useDispatch();
    const { opportunityLostData, allItemsData, allLocationsData, loading } = useSelector((state) => state.inventoryInsights);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [itemFilter, setItemFilter] = useState(null);
    const [locationFilter, setLocationFilter] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    console.log("opportunityLostData",opportunityLostData)
    useEffect(() => {
        dispatch(fetchAllItems()); 
        dispatch(fetchAllLocations());
    }, [dispatch]);

    useEffect(() => {
        if (allItemsData?.items?.length > 0) {
            if (!itemFilter) {
                setItemFilter(allItemsData.items[0]);
            }
            else if(itemFilter && !locationFilter){
                setLocationFilter(allLocationsData.location[0]);
            }
        }
    }, [allItemsData, itemFilter]);
    
    useEffect(() => {
        if (allLocationsData?.location?.length > 0) {
            console.log("locationFilter",locationFilter)
            if (locationFilter) {
                setLocationFilter("");
            }
            setLocationFilter(allLocationsData.location[0]);
        }
    }, [itemFilter,allLocationsData]);
    
    useEffect(() => {
        if (itemFilter) {
            dispatch(fetchAllLocations({ "item_sku": itemFilter }));
            dispatch(fetchOpportunityLost({ "item_sku": itemFilter, "location": locationFilter }));
            setFilteredData(opportunityLostData || []);
        }
    }, [itemFilter, locationFilter, dispatch]);

    useEffect(() => {
        setFilteredData(opportunityLostData || []); 
    }, [opportunityLostData]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const handleChange = (value, type) => {
        if (type === 'item') {
            setItemFilter(value);
            if (value) {
                dispatch(fetchOpportunityLost({ "item_sku": value, "location": locationFilter }));
            } else {
                setLocationFilter(null);
                dispatch(fetchOpportunityLost({ "item_sku": null, "location": null }));
            }
        } else if (type === 'location') {
            setLocationFilter(value);
            if (itemFilter) {
                dispatch(fetchOpportunityLost({ "item_sku": itemFilter, "location": value }));
            }
        }
    };

    const prepareChartData = () => {
        let salesData = filteredData?.sales || [];
        let forecastData = filteredData?.forecast || [];
        let sortedForecastData = [...forecastData].sort(
            (a, b) => new Date(a.aggregation_date) - new Date(b.aggregation_date)
        );
        const expectedStockoutWeek = filteredData?.expected_stockout_week;
        console.log("expectedStockoutWeek",expectedStockoutWeek)

        const addInitialZeroPoint = (data, key) => [
            { x: expectedStockoutWeek, y: 0 },  // Add the zero point at the start
            ...data.map(item => ({
                x: item.aggregation_date,
                y: item[key] || null
            }))
        ];
    
        const series = [
            ...(salesData.length > 0
                ? [{
                    name: 'Sales Amount',
                    data: salesData.map(item => ({
                        x: item.aggregation_date,
                        y: item.sales_amount === 0 ? 0 : item.sales_amount || null // Handle null values
                    }))
                }]
                : []),
                ...(sortedForecastData.length > 0
                ? [
                    {
                        name: 'ETS',
                        data: addInitialZeroPoint(sortedForecastData, 'ets')
                    },
                    {
                        name: 'Prophet',
                        data: addInitialZeroPoint(sortedForecastData, 'prophet')
                    },
                    {
                        name: 'Best Fit',
                        data: addInitialZeroPoint(sortedForecastData, 'bestfit')
                    },
                    {
                        name: 'Moving Average',
                        data: addInitialZeroPoint(sortedForecastData, 'moving_avg')
                    },
                    {
                        name: 'Arima',
                        data: addInitialZeroPoint(sortedForecastData, 'arima')
                    }
                ]
                : [])
        ];
    
        // If no data is available, plot an empty series
        if (series.length === 0) {
            return {
                options: {
                    chart: {
                        id: 'empty-chart',
                        toolbar: { show: false },
                    },
                    xaxis: {
                        type: 'datetime',
                        title: { text: 'Date' },
                        labels: {
                            formatter: function (value) {
                                const date = new Date(value);
                                return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
                            }
                        }
                    },
                    yaxis: {
                        title: { text: 'Sales' },
                        labels: {
                            formatter: function (value) {
                                return value ? (value / 1000).toFixed(2) + 'K' : '0';
                            }
                        }
                    },
                    annotations: {
                        text: 'No data available to display',
                        style: {
                            color: '#999',
                            fontSize: '16px',
                            fontFamily: 'Arial, sans-serif',
                        },
                        position: 'center'
                    }
                },
                series: [{ name: 'Empty Data', data: [] }] // Empty series
            };
        }
    
        return {
            options: {
                chart: {
                    id: 'sales-chart',
                    toolbar: {
                        tools: {
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true
                        }
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        formatter: function (value) {
                            const date = new Date(value);
                            return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
                        }
                    }
                },
                yaxis: {
                    title: { text: 'Sales' },
                    labels: {
                        formatter: function (value) {
                            return value ? (value / 1000).toFixed(2) + 'K' : '0';
                        }
                    }
                },
                colors: ['#7B61FF', '#00008B', '#FFA500', '#FF6384', '#36A2EB', '#FFA07A', '#C0C0C0'],
                stroke: { width: [3, 3, 3, 3, 3] },
                annotations: {
                    xaxis: [
                        {
                            x: new Date(expectedStockoutWeek).getTime(),
                            borderColor: '#FF0000',
                            strokeDashArray: 0,
                            label: {
                                borderColor: '#FF0000',
                                style: { color: '#fff', background: '#FF0000', fontSize: '12px' },
                                text: 'Expected Stockout',
                            }
                        }
                    ]
                }
            },
            series
        };
    };
    
    
    const { options, series } = prepareChartData();
    
    return (
        <Card className='dashboard_card_containers'>
            <Select
              style={{ width: 200, marginBottom: 16, margin: 10 }}
              onChange={(value) => handleChange(value, 'item')}
              showSearch
              value={itemFilter || undefined}
            >
              {(allItemsData?.items || []).map(item => (
                <Option key={item} value={item}>{item}</Option>
              ))}
            </Select>
            <Select
              style={{ width: 200, marginBottom: 16, margin: 10 }}
              placeholder="Select Location"
              onChange={(value) => handleChange(value, 'location')}
              allowClear
              showSearch
              value={locationFilter || undefined}
            >
              {(allLocationsData?.location || []).map(location => (
                <Option key={location} value={location}>{location}</Option>
              ))}
            </Select>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <Chart options={options} series={series} type="line" height={400} />
                    <FullscreenModal 
                        visible={isModalVisible} 
                        onClose={handleClose} 
                        title="Opportunity Lost Graph" 
                        options={options} 
                        series={series}
                        height={350}
                    />
                </>
            )}
        </Card>
    );
}

export default OpportunityLostPlot;